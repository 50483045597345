import React, { useContext } from "react";
import { useEffect, useState } from "react";
import AuthContext from "../../Context/Auth";
import { ToastContainer } from "react-toastify";
import MiniHeader from "./MiniHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete, Edit } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const SpaMembershipPackagesRequest = () => {
  const [packageRequestData, setPackageRequestData] = useState([]);
  const [packageRequestDataLoading, setpackageRequestDataLoading] =
    useState(true);
  const [tempAllServices, setTempAllServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState(1);
  const { authTokens, vendorData } = useContext(AuthContext);
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);

  const [editMode, setEditMode] = useState(false); // Track edit mode
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [formData, setFormData] = useState({
    package_name: "",
    actual_price: "",
    discount_price: "",
    serviceHours: "",
    serviceMinutes: "",
    serviceSeating: "",
  });

  const [selectedServices, setSelectedServices] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const { name, value } = event.target; // Use 'name' instead of 'package_name'
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Set the state based on the 'name' attribute
    }));
  };

  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days != 0) {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating != 0) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours != 0) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes != 0) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  const fetchMassages = async () => {
    setServiceLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/service/?page=${page}&spa_id=${vendorData?.spa}`
      );
      const data = await response.json();

      if (response.ok) {
        if (page === 1) {
          setTempAllServices(data?.results);
        } else {
          setTempAllServices([...tempAllServices, ...data?.results]);
        }

        if (data?.next) {
          setPage(page + 1);
        }
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setServiceLoading(false);
    }
  };

  useEffect(() => {
    fetchMassages();
  }, [vendorData?.spa, page]);

  useEffect(() => {
    fetchPackageRequest();
  }, []);

  useEffect(() => {
    if (tempAllServices?.length > 0) {
      setAllServices(tempAllServices);
    }
  }, [tempAllServices]);

  const fetchPackageRequest = async () => {
    setpackageRequestDataLoading(true);
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/membership-package-request/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access_token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setPackageRequestData(data);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setpackageRequestDataLoading(false);
    }
  };

  const handleOpen = (productData) => {
    if (productData) {
      setEditMode(true); 
      setFormData({
        package_name: productData.package_name,
        actual_price: productData.actual_price,
        discount_price: productData.discount_price,
        serviceHours: productData.offer_timing?.hours || 0,
        serviceMinutes: productData.offer_timing?.minutes || 0,
        serviceSeating: productData.offer_timing?.seating || 0,
      });
      setSelectedPackageId(productData.id);
      setSelectedServices(productData.service_ids || []);
    } else {
      setEditMode(false); 
      setFormData({
        package_name: "",
        actual_price: "",
        discount_price: "",
        serviceHours: "",
        serviceMinutes: "",
        serviceSeating: "",
      });
      setSelectedPackageId(null);
      setSelectedServices([]);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const payload = {
      package_name: formData.package_name,
      discount_price: formData.discount_price,
      offer_timing: {
        hours: formData.serviceHours,
        minutes: formData.serviceMinutes,
        seating: formData.serviceSeating,
      },
      service_ids: selectedServices.length > 0 ? selectedServices : [],
      vendor: vendorData?.id,
      spa: vendorData?.spa,
    };

    try {
      let response;
      if (editMode) {
        // Edit existing package
        response = await fetch(
          `https://trakky.in:8000/spavendor/membership-package-request/${selectedPackageId}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
            },
            body: JSON.stringify(payload),
          }
        );
      } else {
        // Add new package
        response = await fetch(
          "https://trakky.in:8000/spavendor/membership-package-request/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
            },
            body: JSON.stringify(payload),
          }
        );
      }

      if (response.ok) {
        const data = await response.json();
        if (editMode) {
          // Update package in the state
          setPackageRequestData(
            packageRequestData.map((item) =>
              item.id === selectedPackageId ? data : item
            )
          );
          toast.success("Package request updated successfully");
        } else {
          // Add new package to the state
          setPackageRequestData([...packageRequestData, data]);
          toast.success("Package request created successfully");
        }
        setFormData({
          package_name: "",
          actual_price: "",
          discount_price: "",
          serviceHours: "",
          serviceMinutes: "",
          serviceSeating: "",
        });
        setSelectedServices([]);
        handleClose();
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
      toast.error(
        editMode
          ? "An error occurred while updating the package request"
          : "An error occurred while creating the package request"
      );
    }
  };

  const deleteServiceRequest = async (id) => {
    confirm({ description: "Are you sure you want to delete this package?" })
      .then(async () => {
        try {
          const response = await fetch(
            `https://trakky.in:8000/spavendor/membership-package-request/${id}/`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
              },
            }
          );
          if (response.ok) {
            toast.success("Package request deleted successfully");
            setPackageRequestData(
              packageRequestData.filter((item) => item.id !== id)
            );
          } else {
            toast.error("Failed to delete package request");
          }
        } catch (error) {
          toast.error("An error occurred while deleting package request");
        }
      })
      .catch(() => {
        console.log("Deletion cancelled");
      });
  };

  useEffect(() => {
    // when selectedServices changes, update the actual price
    const selectedServicesData = allServices.filter((service) =>
      selectedServices.includes(service.id)
    );

    const actualPrice = selectedServicesData.reduce(
      (total, service) => total + service.price,
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      actual_price: actualPrice,
    }));
  }, [selectedServices]);

  return (
    <>
      <div className="w-full h-full bg-[#EFECFF]">
        <ToastContainer />
        <div className="flex justify-between items-center p-4">
          <h1 class="text-lg font-bold md:text-xl">Package request</h1>
          <div class="flex gap-3 items-center">
            <button
              class="rounded-md bg-black text-white px-4 py-2 text-sm"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpen(null);
              }}
            >
              Add package request
            </button>
          </div>
        </div>
        <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
          <div className="w-full h-full flex flex-col gap-2">
            <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-auto">
              <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
                <thead>
                  <tr>
                    <th className="border border-gray-200 p-2">Sr no.</th>
                    <th className="border border-gray-200 p-2">Package name</th>
                    <th className="border border-gray-200 p-2">
                      Services name
                    </th>

                    <th className="border border-gray-200 p-2">Price</th>
                    <th className="border border-gray-200 p-2">
                      Discounted Price
                    </th>
                    <th className="border border-gray-200 p-2">Time</th>
                    <th className="border border-gray-200 p-2">Request date</th>
                    <th className="border border-gray-200 p-2">Status</th>
                    <th className="border border-gray-200 p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {packageRequestDataLoading ? (
                    <tr className="h-40">
                      <td colSpan="10" className="mx-auto">
                        <CircularProgress
                          sx={{
                            color: "#000",
                            margin: "auto",
                          }}
                        />
                      </td>
                    </tr>
                  ) : packageRequestData?.length > 0 ? (
                    packageRequestData?.map((item, index) => (
                      <tr key={item.id} className="">
                        <td className="border border-gray-200 py-2 px-1">
                          {index + 1}
                        </td>
                        <td className="border border-gray-200 px-2">
                          {item.package_name}
                        </td>
                        <td className="border dangerous-html py-2 border-gray-200 px-2">
                          <ul className="">
                            {item?.service_names?.map((service) => {
                              return <li>{service}</li>;
                            })}
                          </ul>
                        </td>

                        <td className="border border-gray-200 px-2">
                          &#8377;{item.actual_price}
                        </td>
                        <td className="border border-gray-200 px-2">
                          {item.discount_price
                            ? `₹${item.discount_price}`
                            : "-"}
                        </td>
                        <td className="border border-gray-200 px-2">
                          {formateTime(item.offer_timing) || "-"}
                        </td>
                        <td className="border border-gray-200 px-2">
                          {dayjs(item.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-gray-200">
                          <div className=" px-2">
                            {item?.package_status === "pending" ? (
                              <span className=" text-blue-600 bg-blue-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Pending
                              </span>
                            ) : item?.package_status === "approved" ? (
                              <span className=" text-emerald-600 bg-emerald-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Approved
                              </span>
                            ) : item?.package_status === "rejected" ? (
                              <span className=" text-red-600 bg-red-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Rejected
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>

                        <td className="border border-gray-200 px-2">
                          <div className="flex items-center justify-center h-full gap-2">
                            <button
                              onClick={() => handleOpen(item)}
                              className={`${
                                !(item?.package_status === "pending")
                                  ? "invisible"
                                  : "visible"
                              }`}
                            >
                              <EditIcon />
                            </button>
                            <button
                              onClick={() => deleteServiceRequest(item.id)}
                            >
                              <Delete />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="h-40 text-center">
                      <td colSpan="7">No membership request found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <motion.div
          initial={{ y: -30, opacity: 0 }} // Start position
          animate={{ y: 0, opacity: 1 }} // End position
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="w-full max-w-[768px] p-8 bg-white shadow-lg rounded-lg">
            <div className="pb-2 flex justify-between items-center">
              <p className="text-xl font-bold text-center">
                {editMode ? "Edit Packages Request" : " Add Packages Request"}
              </p>
              <span onClick={handleClose} className="cursor-pointer">
                <CloseIcon />
              </span>
            </div>
            <div className="space-y-4 mt-3">
              {/* Offer Name */}
              <div className="flex gap-4">
                <TextField
                  label="Package Name"
                  variant="outlined"
                  fullWidth
                  name="package_name" // This should match the state field
                  value={formData.package_name}
                  onChange={handleChange}
                />
              </div>

              <FormControl fullWidth>
                <InputLabel id="service-select-label">
                  Select Services
                </InputLabel>
                <Select
                  labelId="service-select-label"
                  id="service-select"
                  multiple
                  value={selectedServices} // This should match the state
                  onChange={(e) => setSelectedServices(e.target.value)}
                  input={<OutlinedInput label="Select Services" />}
                  MenuProps={MenuProps}
                  // show name instead of id
                  renderValue={(selected) => {
                    return selected
                      .map((id) => {
                        const service = allServices.find(
                          (service) => service.id === id
                        );
                        return service?.service_names;
                      })
                      .join(", ");
                  }}
                  disabled={serviceLoading}
                >
                  {allServices.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      <Checkbox
                        checked={
                          selectedServices.includes(service.id) ? true : false
                        }
                      />
                      <ListItemText primary={service.service_names} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="flex gap-4">
                <TextField
                  label="Actual Price"
                  variant="outlined"
                  fullWidth
                  name="actual_price"
                  value={formData.actual_price}
                  onChange={handleChange}
                  type="number"
                  disabled={true}
                />
                <TextField
                  label="Discount Price"
                  variant="outlined"
                  fullWidth
                  name="discount_price"
                  value={formData.discount_price}
                  onChange={handleChange}
                  type="number"
                />
              </div>

              {/* Service Hours, Minutes, Seating */}
              <div className="flex gap-4">
                <TextField
                  label="Service Hours"
                  variant="outlined"
                  fullWidth
                  name="serviceHours"
                  value={formData.serviceHours}
                  onChange={handleChange}
                  type="number"
                />
                <TextField
                  label="Service Minutes"
                  variant="outlined"
                  fullWidth
                  name="serviceMinutes"
                  value={formData.serviceMinutes}
                  onChange={handleChange}
                  type="number"
                />
                <TextField
                  label="Service Seating"
                  variant="outlined"
                  fullWidth
                  name="serviceSeating"
                  value={formData.serviceSeating}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              {/* Submit Button */}
              <div className="flex justify-between gap-2 mt-6">
                <button className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition">
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
                >
                  {editMode
                    ? "Update Add Packages Request"
                    : "Add Packages Request"}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </Modal>
    </>
  );
};

export default SpaMembershipPackagesRequest;
