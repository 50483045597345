import React, { useEffect, useState, useContext } from "react";
import "./Settings.css";
import AuthContext from "../Context/Auth";
import toast, { Toaster } from "react-hot-toast";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const SettingsManager = () => {
  const { authTokens } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [inputManager, setinputManager] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/manager/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
      } else {
        toast.error("There was some error while fetching data");
      }
    } catch (error) {
      toast.error("There was some error while fetching data");
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const addManager = async () => {
    if (inputManager) {
      const managername = { managername: inputManager };
      try {
        const response = await fetch(
          "https://trakky.in:8000/spavendor/manager/",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authTokens.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(managername),
          }
        );
        if (response.ok) {
          toast.success("Manager added successfully");
          fetchData()
          setinputManager('')
        } else {
          toast.error("Facing error while adding manager");
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("Please enter manager name");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://trakky.in:8000/spavendor/manager/${id}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authTokens.access_token}`,
          'Content-Type': 'application/json'
        }
      })
      if (response.ok) {
        toast.success('Manager deleted successfully')
        fetchData()
      }
      else {
        toast.error('There is some error deleting manager')
      }
    }
    catch (error) {
      toast.error(error)
    }
  }

  return (
    <div className="sd-main-container">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
        <div className="mb-4">
          <div className="flex">

          <input
              type="text"
              name="managername"
              value={inputManager}
              onChange={(e) => setinputManager(e.target.value)}
              placeholder="Add Manager Name"
              id="managername"
              className="border rounded-l-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              className="bg-indigo-500 text-white p-2 rounded-r-md hover:bg-indigo-600"
              onClick={addManager}
            >
              <AddRoundedIcon />
            </button>
          </div>
        </div>
        <div className="space-y-2">
          {data.length > 0 ? (
            data.map((manager) => (
              <div
                key={manager.id}
                className="flex justify-between items-center p-3 bg-gray-100 rounded-md shadow-sm"
              >
                <span>{manager.managername}</span>
                <span
                  onClick={() => handleDelete(manager.id)}
                  className="cursor-pointer text-red-500 hover:text-red-700"
                >
                  <DeleteRoundedIcon />
                </span>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">No managers added yet.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsManager;