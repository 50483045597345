import React, { useContext, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Quill from "quill";
import "quill/dist/quill.snow.css";
import AuthContext from "../../../Context/Auth";
import { toast, ToastContainer } from "react-toastify";
import { Delete } from "@mui/icons-material";

const CreateMembershipType = ({ editData, onClose }) => {
  const editorRef = useRef(null);

  const { vendorData, authTokens } = useContext(AuthContext);

  const [allServices, setAllServices] = useState([]);
  const [tempAllServices, setTempAllServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [formData, setFormData] = useState({
    membership_name: "",
    validity_in_months: "",
    service_ids: [
      {
        service_id: "",
        points_per_massage: 0,
        name: "",
        price: "",
      },
    ],
    terms_and_conditions: "",
    membership_price: "",
    total_point: "",
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        membership_name: editData?.membership_name,
        validity_in_months: editData?.validity_in_months,
        service_ids: editData?.service_ids,
        terms_and_conditions: editData?.terms_and_conditions,
        membership_price: editData?.membership_price,
        total_point: editData?.total_point,
      });
    }
  }, [editData]);

  const fetchServices = async (page) => {
    setServiceLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/service/?page=${page}&spa_id=${vendorData?.spa}`,
        {}
      );
      const data = await response.json();

      if (response.ok) {
        if (page === 1) {
          let reducedData = await data?.results.map((service) => {
            return {
              id: service?.id,
              service_name: service?.service_names,
              price: service?.price,
            };
          });
          setTempAllServices(reducedData);
        } else {
          let reducedData = await data?.results.map((service) => {
            return {
              id: service?.id,
              service_name: service?.service_names,
              price: service?.price,
            };
          });
          setTempAllServices([...tempAllServices, ...reducedData]);
        }

        if (data?.next) {
          setPage(page + 1);
        }
      } else {
        toast.error(
          `something went wrong while fetching service : ${response.statusText}`
        );
      }
    } catch (error) {
      toast.error(
        `something went wrong while fetching service : ${error.message}`
      );
    } finally {
      setServiceLoading(false);
    }
  };

  useEffect(() => {
    if (tempAllServices.length > 0) {
      setAllServices(tempAllServices);
    }
  }, [tempAllServices]);

  useEffect(() => {
    fetchServices(page);
  }, [page, vendorData?.spa]);

  useEffect(() => {
    editorRef.current = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "bullet" }, { list: "ordered" }],
          [{ color: [] }],
        ],
      },
    });

    if (editData) {
      editorRef.current.root.innerHTML = editData?.terms_and_conditions;
    }
  }, []);

  const handleSubmit = async () => {
    let method = editData ? "PATCH" : "POST";
    let url = `https://trakky.in:8000/spavendor/membership-type-new/`;
    if (editData) {
      url += `${editData?.id}/`;
    }

    let payload = {
      membership_name: formData?.membership_name,
      validity_in_months: parseInt(formData?.validity_in_months),
      terms_and_conditions: editorRef.current.root.innerHTML,
      membership_price: formData?.membership_price,
      total_point: formData?.total_point,
      service_ids: formData?.service_ids?.filter(
        (service) => service?.service_id != ""
      ),
    };

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        if (editData) {
          toast.success("Membership type updated successfully.");
        } else {
          toast.success("Membership type created successfully.");
        }
        setFormData({
          membership_name: "",
          validity_in_months: "",
          service_ids: [
            {
              service_id: "",
              points_per_massage: 0,
              name: "",
              price: "",
            },
          ],
          terms_and_conditions: "",
          membership_price: "",
          total_point: "",
        });

        editorRef.current.root.innerHTML = "";
        setTimeout(() => {
          if (onClose) {
            onClose();
          }
        }, 500);
      } else {
        toast.error(
          "Something went wrong while creating membership type" +
            response.statusText
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while creating membership type" + error.message
      );
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData.service_ids]);

  return (
    <>
      <ToastContainer />
      <div className=" w-full h-[calc(100%-64px)] overflow-auto ">
        <div className=" mb-6 max-w-[724px] rounded-lg p-[25px] bg-white grid gap-x-6 gap-y-5 grid-cols-2 mx-auto py-5 md:py-8">
          <div className=" w-full max-w-[350px] ">
            <TextField
              fullWidth
              id="membership-name"
              label="Membership name"
              variant="outlined"
              value={formData?.membership_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  membership_name: e.target.value,
                });
              }}
            />
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              id="validity"
              label="Validity (months)"
              type="number"
              variant="outlined"
              fullWidth
              value={formData?.validity_in_months}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  validity_in_months: e.target.value,
                });
              }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
          </div>

          {formData?.service_ids?.map((serviceItem, index) => (
            <>
              <div className=" w-full max-w-[350px] ">
                <Autocomplete
                  disablePortal
                  id="service-options"
                  options={allServices}
                  getOptionLabel={(option) =>
                    `${option?.service_name} - ${option?.price}`
                  }
                  disabled={serviceLoading}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Service" />
                  )}
                  value={
                    allServices.find(
                      (service) => service.id === serviceItem?.service_id
                    ) || null
                  }
                  onChange={(e, value) => {
                    let temp = [...formData?.service_ids];
                    temp[index] = {
                      service_id: value?.id || "",
                      points_per_massage: 0,
                      name: value?.service_name || "",
                      price: value?.price || "",
                    };
                    setFormData({
                      ...formData,
                      service_ids: temp,
                    });
                  }}
                />
              </div>
              <div className=" w-full max-w-[350px] relative flex">
                <TextField
                  id="point-per-massage"
                  label="Points ( per massage )"
                  type="number"
                  value={serviceItem?.points_per_massage}
                  variant="outlined"
                  style={{
                    width: "calc(100% - 80px)",
                  }}
                  onChange={(e) => {
                    let temp = [...formData?.service_ids];
                    temp[index] = {
                      ...temp[index],
                      points_per_massage: parseInt(e.target.value),
                    };
                    setFormData({
                      ...formData,
                      service_ids: temp,
                    });
                  }}
                />
                <div
                  className=" w-[80px] flex items-center justify-center"
                  onClick={() => {
                    let temp = [...formData?.service_ids];
                    temp.splice(index, 1);
                    setFormData({
                      ...formData,
                      service_ids: temp,
                    });
                  }}
                >
                  <button className=" bg-red-500 text-white h-8 w-8 rounded-full flex items-center justify-center">
                    <Delete className=" !h-5 !w-5" />
                  </button>
                </div>
              </div>
            </>
          ))}

          <div className=" h-4 flex justify-center col-span-2 w-full gap-5">
            <button
              className=" bg-black text-white py-1 px-4 rounded-md block w-fit -my-2"
              onClick={() => {
                setFormData({
                  ...formData,
                  service_ids: [
                    ...formData?.service_ids,
                    {
                      service_id: "",
                      points_per_massage: 0,
                      name: "",
                      price: "",
                    },
                  ],
                });
              }}
            >
              Add service
            </button>
          </div>

          <div className=" col-span-2 w-full max-w-[724px]">
            <label htmlFor="editor" className=" font-normal pb-2  block">
              Term and Conditions
            </label>
            <div id="editor" style={{ width: "100%", height: "100px" }}></div>
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              id="total-points"
              label="Total points for membership (e.g. 1,2,3..)"
              type="number"
              variant="outlined"
              fullWidth
              value={formData?.total_point}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  total_point: e.target.value,
                });
              }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              id="price"
              label="Membership price"
              type="number"
              variant="outlined"
              fullWidth
              value={formData?.membership_price}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  membership_price: e.target.value,
                });
              }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className=" col-span-2">
            <button
              className=" mx-auto block bg-black text-white py-2 rounded-md px-4 w-fit"
              onClick={handleSubmit}
            >
              {editData ? "Update " : "Create "}
              Membership Type
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMembershipType;
