import React, { useContext, useEffect, useState } from "react";
import { Modal, Typography, Box, Button, TextField, Tab, Tabs } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Search from "@mui/icons-material/Search";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Clients.css'; // Import your CSS file
import InputAdornment from '@mui/material/InputAdornment';
import AuthContext from "../Context/Auth";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';

const Clients = () => {

  const { authTokens } = useContext(AuthContext);


  const [itemCustomerName, setItemCustomerName] = useState('');
  const [itemCustomerPhone, setItemCustomerPhone] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);

  const [customer, setCustomer] = useState([]);

  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");

  const handleEditClick = (client) => {
    setSelectedClient(client);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedClient(null);
  };

  const handleClickOpen = (client) => {
    setSelectedClient(client);
    setViewModalOpen(true);
  }
  const handleClickClose = () => {
    setViewModalOpen(false);
    setSelectedClient(null);
    setSelectedTab(0);
  }

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    setCustomer((prevData) =>
      prevData.map((client) =>
        client.number === selectedClient.number ? selectedClient : client
      )
    );
    handleEditModalClose();
  };

  const getCustomerData = async () => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-table/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCustomer(data);
        setFilterData(data);

        setSelectedClient(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const handlEditDdetails = async (e) => {
    e.preventDefault();

    const data = {
      customer_name: itemCustomerName,
      customer_phone: itemCustomerPhone,
    };

    try {
      const res = await fetch(`https://trakky.in:8000/spavendor/customer-table/${selectedClient?.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        const data = await res.json();
        toast.success("Client Details Updated Successfully");
        setItemCustomerName('');
        setItemCustomerPhone('');
        setSelectedClient(null);
        getCustomerData();
        handleEditModalClose();
      }
      else {
        toast.error('Please give proper credentials')
      }

    }
    catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  }


  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    setItemCustomerName(selectedClient?.customer_name);
    setItemCustomerPhone(selectedClient?.customer_phone);
  }, [selectedClient]);

  useEffect(() => {
    if (search === "") {
      setFilterData(customer);
    } else {
      setFilterData(
        customer?.filter((cust) =>
          cust?.customer_name?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, customer]);

  const [customerAppointments, setCustomerAppointments] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderCategoryTable = () => {
    if (!selectedClient) return null;

    if (selectedTab === 0) {
      return (
        <div className=" max-w-[100vw] md:max-w-[calc(100vw-72px)]">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-gray-200 p-2">Name</th>
                <th className="border border-gray-200 p-2">Customer Type</th>
                <th className="border border-gray-200 p-2">Appointment Date</th>
                <th className="border border-gray-200 p-2">Customer</th>
                <th className="border border-gray-200 p-2">Actual Price</th>
                <th className="border border-gray-200 p-2">Final Price</th>
                <th className="border border-gray-200 p-2">Paid</th>
                <th className="border border-gray-200 p-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {selectedClient.appointments && selectedClient.appointments.length > 0 ? (
                selectedClient.appointments.map((appointment, index) => (
                  <tr key={index} className="text-center">
                    <td className="border border-gray-200 p-2">{appointment.customer_name}</td>
                    <td className="border border-gray-200 p-2">{appointment.customer_type}</td>
                    <td className="border border-gray-200 p-2">{appointment.date}</td>
                    <td className="border border-gray-200 p-2">{appointment.customer_name}</td>
                    <td className="border border-gray-200 p-2">{appointment.actual_amount}</td>
                    <td className="border border-gray-200 p-2">{appointment.final_amount}</td>
                    <td className="border border-gray-200 p-2">{appointment.amount_paid}</td>
                    <td className="border border-gray-200 p-2">{appointment.appointment_status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">No Appointments Found</td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
      );
    }
    // else if (selectedTab === 1) {
    //   return (
    //     <table className="w-full border-collapse">
    //       <thead>
    //         <tr>
    //           <th className="border border-gray-200 p-2">Discount</th>
    //           <th className="border border-gray-200 p-2">Tex</th>
    //           <th className="border border-gray-200 p-2">Price</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //       {selectedClient.sell && selectedClient.sell.length > 0 ? (
    //           selectedClient.sell.map((product, index) => (
    //             <tr key={index} className="text-center">
    //               <td className="border border-gray-200 p-2">{product.product_name}</td>
    //               <td className="border border-gray-200 p-2">{product.product_brand}</td>
    //               <td className="border border-gray-200 p-2">{product.price_per_unit}</td>
    //             </tr>
    //           ))
    //         ) : (
    //           <tr>
    //             <td colSpan="3" className="text-center">No Product History Found</td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //   );
    // }
    else if (selectedTab === 1) {
      return (
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border border-gray-200 p-2">Membership Plan</th>
              <th className="border border-gray-200 p-2">Start Date</th>
              <th className="border border-gray-200 p-2">
                total points
              </th>
              <th className="border border-gray-200 p-2">
                remaining points
              </th>
              <th className="border border-gray-200 p-2">Validity</th>

            </tr>
          </thead>
          <tbody>
            {selectedClient.membership && selectedClient.membership.length > 0 ? (
              selectedClient.membership.map((membership, index) => (
                <tr key={index} classaName="text-center">
                  <td className="border border-gray-200 p-2">{membership.membership_type_detail.membership_name} - {membership.membership_code}</td>
                  <td className="border border-gray-200 p-2">{membership.created_at?.split("T")?.[0]}</td>
                  <td className="border border-gray-200 p-2">
                    {
                      membership?.membership_type_detail?.total_point
                    }
                  </td>
                  <td className="border border-gray-200 p-2">
                    {
                      membership?.remaining_point
                    }
                  </td>
                  <td className="border border-gray-200 p-2">{membership?.
                    membership_type_detail
                    ?.
                    validity_in_months
                  }</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">No Membership Found</td>
              </tr>
            )}
          </tbody>
        </table>
      );
    }
  };


  return (
    <div className=" w-full h-full bg-[#EFECFF] pl-0 md:pl-[72px]">
      <div className="flex items-center justify-between h-14 w-full md:h-16 px-2 md:px-6">
        <h1 className="text-lg font-bold md:text-xl">Customer Details</h1>
      </div>
      <div className=" w-full  h-[calc(100%-60px)] md:h-[calc(100%-68px)]  mt-1 ">
        <div className=" w-full h-full flex flex-col gap-2">
          <div className=" w-full h-14 px-3 flex py-2 gap-2 shrink-0">
            <input
              type="text"
              name="search"
              id="search"
              className=" shrink grow h-full w-full max-w-[min(100%,400px)] rounded-xl outline-none active:outline-none focus:outline-none px-4"
              placeholder="Search name..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className=" bg-[#512Dc8] h-full w-20 flex items-center justify-center text-center text-sm text-white rounded-xl border-2 border-[#EFECFF]"
              onClick={() => {
                setSearch("");
              }
              }
            >
              Reset
            </button>
          </div>
          <div className="  w-full h-full pb-2 px-4  max-w-[100vw] md:max-w-[calc(100vw-72px)] overflow-auto">
            <table className=" border-collapse w-full bg-white rounded-lg text-center min-w-max">
              <tr>
                <th className=" border border-gray-200 p-2">Sr. no</th>
                <th className=" border border-gray-200 p-2">Name</th>
                <th className=" border border-gray-200 p-2">Phone number</th>
                <th className=" border border-gray-200 p-2">Customer type</th>
                <th className=" border border-gray-200 p-2">Appointment Count</th>
                <th className=" border border-gray-200 p-2">Membership Count</th>
                <th className=" border border-gray-200 p-2">View</th>
                <th className=" border border-gray-200 p-2">Action</th>
              </tr>

              {loading ? (
                <tr className=" h-40 ">
                  <td colSpan="12" className=" mx-auto"> <CircularProgress
                    sx={{
                      color: "#000",
                      margin: "auto",
                    }}
                  /></td>
                </tr>
              ) : filterData?.length > 0 ? (
                filterData?.map((item, index) => (
                  <tr>

                    <td className=" border border-gray-200 p-2">
                      {index + 1}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.customer_name}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.customer_phone}
                    </td>

                    <td className=" border border-gray-200 p-2">
                      {item?.customer_type}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.appointments_count}
                    </td>

                    <td className=" border border-gray-200 p-2">
                      {item?.memberships_count}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      < VisibilityIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickOpen(item)}
                      />
                    </td>
                    <td className=" border border-gray-200 p-2">
                      <div className=" flex items-center justify-center h-full gap-2">
                        <button
                          onClick={() => {
                            handleEditClick(item);
                          }}
                        >
                          <EditIcon />
                        </button>
                      </div>
                    </td>

                  </tr>
                ))
              ) : (
                <tr className=" h-40 text-center">
                  <td colSpan="7">No customer Data</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>


      <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            minWidth: 400,
          }}
        >
          <Typography variant="h5" mb={2}>
            Edit Client Details
          </Typography>
          {selectedClient && (
            <form >
              <div className="clientsEditModalTextFields">
                <TextField
                  label="Client name"
                  name="name"
                  value={itemCustomerName}
                  onChange={(e) => {
                    setItemCustomerName(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Contact no."
                  name="contact"
                  type="number"
                  value={itemCustomerPhone}
                  onChange={
                    (e) => {
                      // setItemCustomerPhone(e.target.value);

                      if (e.target.value.length <= 10) {
                        setItemCustomerPhone(e.target.value);
                      }
                    }
                  }
                  fullWidth
                  margin="normal"
                />

              </div>
              <Typography variant="body2" color="textSecondary">
                Old contact no.: {selectedClient?.customer_phone}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handlEditDdetails}
                sx={{ mt: 2, backgroundColor: "black" }}
              >
                Save
              </Button>
            </form>
          )}
        </Box>
      </Modal>


      <Modal open={viewModalOpen} onClose={handleClickClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.25)",
            p: 4,
            minWidth: 400,
            maxWidth: 1000,
            width: "90vw", // More flexible width
          }}
        >
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800">Client Details</h2>
            <CloseIcon className="cursor-pointer hover:text-red-500 transition" onClick={handleClickClose} />
          </div>
          <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} className="mt-4">
            <Tab label="Appointments" />
            {/* <Tab label="Product Purchase History" /> */}
            <Tab label="Membership" />
          </Tabs>
          <Box mt={2}>
            {renderCategoryTable()}
          </Box>
        </Box>
      </Modal>


    </div>
  );
};

export default Clients;