import React from "react";
import MiniHeader from "./MiniHeader";
import { ToastContainer } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/Auth";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useConfirm } from "material-ui-confirm";
import { Delete, Edit } from "@mui/icons-material";
import CreateCustomerMembership from "./form/CreateCustomerMembership";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Toaster } from "react-hot-toast";

const CustomerMembership = () => {
  const { authTokens } = useContext(AuthContext);

  const confirm = useConfirm();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [drawerData, setDrawerdata] = useState(null);
  const [paymentDrawerData, setPaymentDrawerdata] = useState(null);
  const [modalMembershipType, setModalMembershipType] = useState(null);
  const [open, setOpen] = useState(false);
  const [customerMembership, setCustomerMembership] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
  const [paymentData, setPaymentData] = useState([]);

  const handleOpen = (membershipType) => {
    setModalMembershipType(membershipType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalMembershipType(null);
    fetchCustomerMembership();
  };

  const fetchCustomerMembership = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-membership-new/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setCustomerMembership(data);
        setFilterData(data);
      } else {
        toast.error(data.detail);
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentDetails = async (id) => {

    if (!id) {
      return;
    }

    let url = `https://trakky.in:8000/spavendor/payment-history/?customer_membership=${id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setPaymentData(data);
      } else {
        toast.error(data.detail);
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const deleteCustomerMembership = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete this membership type?`,
      });

      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-membership-new/${id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      if (response?.ok) {
        toast.success("Customer membership successfully deleted");
        setCustomerMembership(
          customerMembership.filter((membership) => membership.id !== id)
        );
      } else {
        toast.error(
          "Failed to delete customer membership :",
          response.statusText
        );
      }
    } catch (error) {
      if (error === undefined || error === "cancel") {
        console.log("Deletion cancelled");
        return;
      }

      toast.error("An error occurred: " + error);
    }
  };

  useEffect(() => {
    fetchCustomerMembership();
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilterData(customerMembership);
    } else {
      // setFilterData(
      //   customerMembership?.filter((membership) => {
      //     return (
      //       (membership?.customer_name
      //         ? membership?.customer_name
      //             ?.toLowerCase()
      //             .includes(search.toLowerCase())
      //         : membership?.customer_data?.customer_name
      //             ?.toLowerCase()
      //             .includes(search.toLowerCase())) ||
      //       (membership?.customer_number
      //         ? membership?.customer_number?.toString()?.includes(search)
      //         : membership?.customer_data?.customer_phone
      //             ?.toString()
      //             ?.includes(search)) ||
      //       membership?.membership_code.includes(search)
      //     );
      //   })
      // );
      setFilterData(
        customerMembership?.filter((membership) => {
          return (
            (membership?.customer?.customer_name
              ? membership?.customer?.customer_name
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              : "") ||
            (membership?.customer?.customer_phone
              ? membership?.customer?.customer_phone
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              : "") ||
            (membership?.membership_code
              ? membership?.membership_code
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              : "")
          );
        })
      );
    }
  }, [search, customerMembership]);

  const resetSearch = () => {
    setSearch("");
    setFilterData(customerMembership);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <div className="w-full h-full bg-[#EFECFF]">
        <ToastContainer />
        <MiniHeader title="Customer membership" />
        <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
          <div className="w-full h-full flex flex-col gap-2">
            <div className="w-full h-14 px-3 flex py-2 gap-2 shrink-0">
              <input
                type="text"
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="shrink grow h-full w-full max-w-[min(100%,400px)] rounded-xl outline-none active:outline-none focus:outline-none px-4"
                placeholder="Search name , phone number , membership code..."
              />
              <button
                onClick={resetSearch}
                className="bg-[#512DC8] h-full w-20 flex items-center justify-center text-center text-sm text-white rounded-xl border-2 border-[#EFECFF]"
              >
                Reset
              </button>
            </div>

            <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-auto">
              <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
                <thead>
                  <tr>
                    <th className="border border-gray-200 p-2">
                      Customer Name
                    </th>
                    <th className="border border-gray-200 p-2">
                      Customer number
                    </th>
                    <th className="border border-gray-200 p-2">
                      Membership code
                    </th>
                    <th className="border border-gray-200 p-2">Total points</th>
                    <th className="border border-gray-200 p-2">
                      Remaining points
                    </th>
                    <th className="border border-gray-200 p-2">
                      Membership price
                    </th>
                    <th className="border border-gray-200 p-2">Paid amount</th>
                    <th className="border border-gray-200 p-2">Due amount</th>
                    <th className="border border-gray-200 p-2">
                      Payment details
                    </th>
                    <th className="border border-gray-200 p-2">
                      Membership type
                    </th>
                    <th className="border border-gray-200 p-2">Date</th>
                    <th className="border border-gray-200 p-2">
                      Terms & conditions
                    </th>
                    <th className="border border-gray-200 p-2">Branch name</th>
                    <th className="border border-gray-200 p-2">Manager name</th>
                    <th className="border border-gray-200 p-2">Status</th>
                    <th className="border border-gray-200 p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="h-40">
                      <td colSpan="11" className="mx-auto">
                        <CircularProgress
                          sx={{
                            color: "#000",
                            margin: "auto",
                          }}
                        />
                      </td>
                    </tr>
                  ) : filterData?.length > 0 ? (
                    filterData?.map((membership) => {
                      return (
                        <tr key={membership?.id}>
                          <td className="border border-gray-200 p-2">
                            {membership?.customer
                              ? membership?.customer?.customer_name
                              : "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.customer
                              ? membership?.customer?.customer_phone
                              : "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.membership_code ?? "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.membership_type_detail?.total_point ??
                              "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.remaining_point}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.membership_type_detail
                              ?.membership_price ?? "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.amount_paid}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.due_amount}
                          </td>
                          <td className="border border-gray-200 p-2">
                            <InfoOutlinedIcon
                              sx={{ fontSize: "16px" }}
                              className="cursor-pointer"
                              onClick={() => {
                                setPaymentDrawerdata(membership);
                                setPaymentDrawerOpen(true);
                                fetchPaymentDetails(membership?.id);
                              }}
                            />
                          </td>
                          <td className="border border-gray-200 p-2">
                            {
                              membership?.membership_type_detail
                                ?.membership_name
                            }
                            <InfoOutlinedIcon
                              sx={{ fontSize: "16px" }}
                              className="ml-1 cursor-pointer"
                              onClick={() => {
                                setDrawerdata(membership);
                                toggleDrawer(true)();
                              }}
                            />
                          </td>

                          <td className="border border-gray-200 p-2">
                            {membership?.created_at?.split("T")[0]}
                          </td>
                          <td className="border border-gray-200 p-2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: membership?.terms_and_conditions,
                              }}
                              className="dangerous-html w-full"
                            ></div>
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.branch_name}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.manager_detail?.managername ?? "-"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            {membership?.active ? "Active" : "Inactive"}
                          </td>
                          <td className="border border-gray-200 p-2">
                            <div className="flex items-center justify-center h-full gap-2">
                              <button onClick={() => handleOpen(membership)}>
                                <Edit />
                              </button>
                              <button
                                onClick={() => {
                                  deleteCustomerMembership(membership?.id);
                                }}
                              >
                                <Delete />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="11" className="p-2">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box
              sx={{
                ...style,
                maxWidth: "800px",
                width: "100%",
                border: "none",
                outline: "none",
                padding: "0",
                height: "auto",
                maxHeight: "95vh",
                overflow: "auto",
                borderRadius: "10px",
              }}
            >
              {modalMembershipType && (
                <CreateCustomerMembership
                  editData={modalMembershipType}
                  onClose={handleClose}
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerdata(null);
          toggleDrawer(false)();
        }}
      >
        <Box
          sx={{
            width: 360,
            height: "100%",
            bgcolor: "#efecff",
            borderLeft: "1px solid",
            borderColor: "divider",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid",
              borderColor: "divider",
              bgcolor: "#efecff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "text.primary" }}
            >
              Membership Details
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Membership name:</strong>{" "}
              {drawerData?.membership_type_detail?.membership_name}
            </Typography>

            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Price:</strong> ₹
              {drawerData?.membership_type_detail?.membership_price}
            </Typography>

            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Validity (Months):</strong>{" "}
              {drawerData?.membership_type_detail?.validity_in_months}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Terms and Conditions:</strong>
              <Box
                component="div"
                sx={{ mt: 1 }}
                dangerouslySetInnerHTML={{
                  __html:
                    drawerData?.membership_type_detail?.terms_and_conditions,
                }}
                className="dangerous-html w-full"
              />
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Total point:</strong>
              {drawerData?.membership_type_detail?.total_point}
            </Typography>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={paymentDrawerOpen}
        onClose={() => {
          setPaymentDrawerdata(null);
          setPaymentDrawerOpen(false);
        }}
        sx={{ zIndex: 1000, width: "fit-content" }}
      >
        <Box
          sx={{
            width: "fit-content",
            height: "100%",
            bgcolor: "#efecff",
            borderLeft: "1px solid",
            borderColor: "divider",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          // onKeyDown={toggleDrawer(false)}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setPaymentDrawerdata(null);
              setPaymentDrawerOpen(false);
            }
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setPaymentDrawerdata(null);
              setPaymentDrawerOpen(false);
            }
          }}
        >
          <div className="w-full h-14 px-3 flex py-2 gap-2 shrink-0 border-b border-gray-400">
            <div className="w-full h-full font-semibold flex items-center gap-2">
              Membership Payment Details
            </div>
            <IconButton
              onClick={() => {
                setPaymentDrawerdata(null);
                setPaymentDrawerOpen(false);
              }}
            >
              <CloseIcon sx={{
                color: "black",
                fontSize: "20px",
              }} />
            </IconButton>
          </div>

          <Box sx={{ p: 2, width: "fit-content" }}>
            <div className="px-2 py-2 flex flex-col gap-2">
              {paymentData?.length > 0 ? (
                paymentData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex min-w-80 rounded-md md:min-w-96 p-3 justify-between items-center  border border-dashed border-gray-300 bg-white"
                    >
                      <div className=" flex flex-col gap-1">
                        <p className=" text-sm">
                          <strong>Payment date : </strong>
                            <span className="text-gray-600 text-sm">
                           {item?.created_at?.split("T")[0]}
                            </span>
                        </p>
                        <p className=" text-sm">
                          <strong>Amount : </strong> 
                          <span className="text-gray-600 text-sm">
                          ₹{item?.payment_amount }
                          </span>
                        </p>
                        <p className=" text-sm">
                          <strong>Payment Receiver :  </strong>{" "}
                          <span className="text-gray-600 text-sm">
                          {item?.manager_detail?.managername}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No payment details found</p>
              )}
            </div>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CustomerMembership;
