import React, { useContext, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuthContext from "../../Context/Auth";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import MiniHeader from "./MiniHeader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const SpaMembershipPackageList = () => {
  const { vendorData } = useContext(AuthContext);

  const [packageLoading, setPackageLoading] = useState(false);
  const [packageData, setPackageData] = useState([]);

  const fetchPackages = async () => {
    setPackageLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/member-ship/?spa=${vendorData?.spa}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setPackageData(data);
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setPackageLoading(false);
    }
  };

  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days !== "0") {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating !== "0") {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours !== "0") {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes !== "0") {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  useEffect(() => {
    fetchPackages();
  }, [vendorData?.spa]);

  return (
    <div className=" w-full h-full">
      <ToastContainer />
      <MiniHeader title="Our Packages" />
      <div className=" w-full max-w-[100vw] h-[calc(100%-60px)] md:h-[calc(100%-68px)] md:p-3 overflow-auto mt-1 ">
        <div className=" w-full flex flex-col gap-2 items-center justify-center px-2 min-w-[500px]">
          {packageLoading ? (
            <div className=" h-60 flex items-center justify-center">
              <CircularProgress
                sx={{
                  color: "#000",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          ) : packageData?.length > 0 ? (
            packageData?.map((packageItem, index) => (
              <div
                key={index}
                className=" flex flex-col gap-3 mb-3 mt-3 w-full"
              >
                <div className=" flex justify-between items-center gap-2 px-5">
                  <div className=" h-6 w-6 flex items-center justify-center">
                    {/* <MenuIcon className=" !h-4 !w-4" /> */}
                    <span className=" text-lg font-semibold">{index + 1}.</span>
                  </div>
                  <div className=" grow flex gap-1 items-center ">
                    <h1 className=" text-lg font-semibold">
                      {packageItem?.package_name}
                    </h1>
                    &nbsp;&nbsp;&nbsp;
                    <span className=" text-base font-semibold ">
                      &#8377;{packageItem?.discount_price}
                    </span>
                    <span className=" text-gray-400">
                      &#8377;
                      <del>
                        <span className=" text-sm font-medium text-gray-400">
                          {packageItem?.actual_price}
                        </span>
                      </del>
                    </span>
                  </div>
                  <div className=" h-6 w-6 flex items-center justify-center">
                    {/* <MoreVertIcon className=" !h-5 !w-5" /> */}
                  </div>
                </div>
                <div className="px-2">
                  {packageItem?.service_included?.map((item, index1) => (
                    <div className=" px-2 py-3 items-center gap-2 md:gap-3 w-full flex grow border border-t-slate-200 border-r-slate-200 last:border-b-slate-200 border-l-4 border-l-blue-200">
                      <div className=" h-6 w-4 flex items-center justify-center">
                        {/* <MenuIcon className=" !h-4 !w-4 text-gray-400" /> */}
                        {/* <span className=" font-semibold">{index1 + 1}.</span> */}
                        <FiberManualRecordIcon className=" !h-2 !w-2" />
                      </div>
                      <div className=" grow">
                        <h3 className=" line-clamp-1">
                          {item?.service_names ?? "No service Name"}
                        </h3>
                      </div>
                      <div className=" text-sm line-clamp-1 min-w-60 max-w-60">
                        {formateTime(item?.service_time)}
                      </div>
                      <div className=" min-w-16 pl-2">
                        <span className=" text-sm font-medium min-w-16 block">
                          &#8377;
                          {item?.price ?? "-"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className=" w-full h-52 flex items-center justify-center">
              <h1 className=" text-lg font-bold">No Packages Found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpaMembershipPackageList;
