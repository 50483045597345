export const data = {
  chair_details: [
    { name: "Chair1", status: "Free" },
    { name: "Chair2", status: "Busy" },
    { name: "Chair3", status: "Busy" },
    { name: "Chair4", status: "Free" },
    { name: "Chair5", status: "Free" },
    { name: "Chair6", status: "Busy" },
  ],
  services: [
    { name: "service1", price: "₹1500" },
    { name: "service2", price: "₹3500" },
    { name: "service3", price: "₹1000" },
    { name: "service4", price: "₹2000" },
  ],
  offers: [
    { name: "haircut", price: "150" },
    { name: "beard", price: "200" },
    { name: "haircolor", price: "300" },
  ],
  staff: [
    { name: "Sitish", status: "Free" },
    { name: "Rahul", status: "Busy" },
    { name: "Veer", status: "Free" },
  ],
};

export const tableData = {
  columns: [
    { name: "Date" },
    { name: "Total Appointments" },
    { name: "Cancelled Appointments" },
    // {name:'Completed Appointment'},
    { name: "Total Customers" },
  ],
  data: [
    {
      date: "May 13 2024",
      day: "Monday",
      TAppointments: "15",
      CAppointments: "5",
      TotalCustomer: "10",
    },
    {
      date: "May 14 2024",
      day: "Tuesday",
      TAppointments: "17",
      CAppointments: "7",
      TotalCustomer: "12",
    },
    {
      date: "May 15 2024",
      day: "Wednesday",
      TAppointments: "12",
      CAppointments: "3",
      TotalCustomer: "8",
    },
    {
      date: "May 16 2024",
      day: "Thursday",
      TAppointments: "20",
      CAppointments: "8",
      TotalCustomer: "15",
    },
    {
      date: "May 17 2024",
      day: "Friday",
      TAppointments: "14",
      CAppointments: "4",
      TotalCustomer: "9",
    },
    {
      date: "May 18 2024",
      day: "Saturday",
      TAppointments: "18",
      CAppointments: "6",
      TotalCustomer: "11",
    },
    {
      date: "May 19 2024",
      day: "Sunday",
      TAppointments: "16",
      CAppointments: "5",
      TotalCustomer: "13",
    },
  ],
};
export const appointments = [
  {
    date: "May 18 2024",
    service: "Hair Cut",
    status: 'Pending',
    customerName: "Priyansh Bhavsar",
    staff: "XYZ",
    customerType: "Member",
    amount: "2000",
    paymentStatus: "Paid",
  },
  {
    date: "May 18 2024",
    service: "Manicure",
    status: 'Completed',
    customerName: "Alice Smith",
    staff: "ABC",
    customerType: "Non-Member",
    amount: "1500",
    paymentStatus: "Paid",
  },
  {
    date: "May 18 2024",
    service: "Massage",
    status: "On going",
    customerName: "John Doe",
    staff: "DEF",
    customerType: "Member",
    amount: "2500",
    paymentStatus: "Paid",
  },
  {
    date: "May 19 2024",
    service: "Facial",
    status:"Pending",
    customerName: "Emily Brown",
    staff: "GHI",
    customerType: "Non-Member",
    amount: "1800",
    paymentStatus: "Paid",
  },
  {
    date: "May 19 2024",
    service: "Pedicure",
    status: 'Completed',
    customerName: "Michael Johnson",
    staff: "JKL",
    customerType: "Member",
    amount: "1600",
    paymentStatus: "Paid",
  },
  {
    date: "May 19 2024",
    service: "Shave",
    status: "Completed",
    customerName: "David Lee",
    staff: "MNO",
    customerType: "Non-Member",
    amount: "1200",
    paymentStatus: "Paid",
  },
];