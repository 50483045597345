import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../Context/Auth";
import MiniHeader from "./MiniHeader";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Edit } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import dayjs from "dayjs";
import moment from "moment";
import { motion } from "framer-motion";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import toast, { Toaster } from "react-hot-toast";
import { IconButton } from "@mui/material";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";

const OffersRequest = () => {
  const [offerRequestData, setOfferRequestData] = useState([]);
  const [offerRequestDataLoading, setOfferRequestDataLoading] = useState(true);
  const [editProductData, setEditProductData] = useState(null);
  const { authTokens } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const confirm = useConfirm();


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleOpen = (productData) => {
    if (productData) {
      setEditProductData(productData); // Set the data to be edited
    } else {
     
      setEditProductData(null); // Reset for adding a new offer
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days != 0) {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating != 0) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours != 0) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes != 0) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  const featchofferRequest = async () => {
    setOfferRequestDataLoading(true);
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/offer-request/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access_token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setOfferRequestData(data);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setOfferRequestDataLoading(false);
    }
  };

  useEffect(() => {
    featchofferRequest();
  }, []);

  const handleEditClick = (item) => {
    setEditProductData(item);
    setOpen(true); // Open the modal
  };

  const handleDeleteClick = async (id) => {
    confirm({
      description: "Are you sure you want to delete this package?",
    }).then(async () => {
      try {
        const response = await fetch(
          `https://trakky.in:8000/spavendor/offer-request/${id}/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Offer Request Deleted Successfully");
          setOfferRequestData((prevData) =>
            prevData.filter((item) => item.id !== id)
          );
        } else {
          const error = await response.json();
          toast.error(error.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting the offer request");
      }
    });
  };

  return (
    <>
      <div className="w-full h-full bg-[#EFECFF]">
        <Toaster />
        <div class="flex items-center justify-between h-14 w-full md:h-16 px-2 md:px-6">
          <div class="h-5 w-5 flex items-center md:hidden">
            <ArrowBackIcon className="w-full" />
          </div>
          <div>
            <h1 class="text-lg font-bold md:text-xl">Offer request</h1>
          </div>
          {window.innerWidth > 768 ? (
            <div class="flex gap-3 items-center">
              {/* <Link to='Select-Supplier'> */}
              <button
                class="rounded-md bg-black text-white px-4 py-2 text-sm"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpen(null);
                }}
              >
                Add offer request
              </button>
              {/* </Link> */}
            </div>
          ) : (
            <div className="flex gap-2 items-center">
              <button className="rounded-md border border-gray-300 p-1 ">
                <MoreVertIcon className="h-5 w-5" />
              </button>
              <button
                className="rounded-md border border-gray-300 p-1"
                onClick={() => {
                  handleOpen(null);
                }}
              >
                <AddIcon className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>

        <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
          <div className="w-full h-full flex flex-col gap-2">
            <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-y-auto overflow-x-auto">
              <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
                <thead>
                  <tr>
                  <th className="border border-gray-100 p-2">Sr no.</th>
                  <th className="border border-gray-100 p-2">Offer name</th>
                  <th className="border border-gray-100 p-2">Offer type</th>
                  <th className="border border-gray-100 p-2">
                    Offer percentage
                  </th>
                  <th className="border border-gray-100 p-2">Massage name</th>
                  <th className="border border-gray-100 p-2">Price</th>
                  <th className="border border-gray-100 p-2">Discounted price</th>
                  <th className="border border-gray-100 p-2">
                    Term and Condition
                  </th>
                  <th className="border border-gray-100 p-2">Created date</th>
                  <th className="border border-gray-100 p-2 min-w-32">
                    How to avail
                  </th>
                  <th className="border border-gray-100 p-2">Coupon code</th>
                    <th className="border border-gray-100 p-2">Status</th>
                    <th className="border border-gray-100 p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offerRequestDataLoading ? (
                    <tr className="h-40">
                      <td colSpan="11" className="mx-auto">
                        <CircularProgress
                          sx={{
                            color: "#000",
                            margin: "auto",
                          }}
                        />
                      </td>
                    </tr>
                  ) : offerRequestData?.length > 0 ? (
                    offerRequestData?.map((item, index) => {
                      return (
                        <tr key={item?.id}>
                           <td className="p-2 border-gray-200 border">
                          {index + 1}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_name ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_type ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_percentage ? `${item?.offer_percentage}%` : "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.service_name ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.actual_price ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.discount_price ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border dangerous-html">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.terms_and_conditions,
                            }}
                          />
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.created_at ? item?.created_at?.split("T")[0] : "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.how_to_avail ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.coupon_code ?? "-"}
                        </td>
                          <td className="border border-gray-200 px-2">
                            {item?.offer_status === "pending" ? (
                              <span className=" text-blue-600 bg-blue-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Pending
                              </span>
                            ) : item?.offer_status === "approved" ? (
                              <span className=" text-emerald-600 bg-emerald-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Approved
                              </span>
                            ) : item?.offer_status === "rejected" ? (
                              <span className=" text-red-600 bg-red-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Rejected
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {
                              <button
                                onClick={() => handleEditClick(item)}
                                className={`${
                                  !(item?.offer_status === "pending")
                                    ? "invisible"
                                    : "visible"
                                }`}
                              >
                                <BootstrapTooltip title="Edit" placement="top">
                                  <EditIcon />
                                </BootstrapTooltip>
                              </button>
                            }
                            <button onClick={() => handleDeleteClick(item.id)}>
                              <BootstrapTooltip title="Delete" placement="top">
                                <Delete />
                              </BootstrapTooltip>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="11" className="p-2">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <motion.div
          initial={{ y: -30, opacity: 0 }} // Start position
          animate={{ y: 0, opacity: 1 }} // End position
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <OfferAddPage
            handleClose={handleClose}
            editProductData={editProductData}
            refreshData={featchofferRequest}
          />
        </motion.div>
      </Modal>
    </>
  );
};

export default OffersRequest;

const OfferAddPage = ({ handleClose, editProductData, refreshData }) => {
  const { authTokens, vendorData } = useContext(AuthContext);
  const editorRef = useRef(null);
  const [offerRequestData, setOfferRequestData] = useState([]);
  const [formData, setFormData] = useState({});

  const [serviceLoading, setServiceLoading] = useState(false);
  const [tempAllServices, setTempAllServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedServices, setSelectedServices] = useState(null);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  console.log("selected services", selectedServices);

  const fetchMassages = async () => {
    setServiceLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/service/?page=${page}&spa_id=${vendorData?.spa}`
      );
      const data = await response.json();

      if (response.ok) {
        if (page === 1) {
          setTempAllServices(data?.results);
        } else {
          setTempAllServices([...tempAllServices, ...data?.results]);
        }

        if (data?.next) {
          setPage(page + 1);
        }
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setServiceLoading(false);
    }
  };

  useEffect(() => {
    fetchMassages();
  }, [vendorData?.spa, page]);

  useEffect(() => {
    if (tempAllServices?.length > 0) {
      setAllServices(tempAllServices);
    }
  }, [tempAllServices]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    editorRef.current = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "bullet" }, { list: "ordered" }],
          [{ color: [] }],
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (editProductData) {
      setFormData({
        offer_name: editProductData.offer_name,
        offer_type: editProductData.offer_type,
        offer_percentage: editProductData.offer_percentage,
        coupon_code: editProductData.coupon_code,
        how_to_avail: editProductData.how_to_avail,
        massage_price: editProductData.actual_price,

      }); // Populate form with edit data
      editorRef.current.root.innerHTML = editProductData.terms_and_conditions;
    }
  }, [editProductData]);

  const handleSubmit = async () => {

    
    const payload = {
      spa: vendorData?.spa,
      offer_type: formData.offer_type,
      service: selectedServices,
      offer_percentage: formData.offer_percentage,
      offer_name: formData.offer_name,
      terms_and_conditions: editorRef.current.root.innerHTML,
      coupon_code: formData.coupon_code,
      how_to_avail: formData.how_to_avail,
      discount_price: formData?.offer_type === "massage specific" ? (
        formData?.massage_price - (formData?.massage_price * formData?.offer_percentage) / 100
      ).toFixed(2) : null,
    };

    try {
      const response = editProductData
        ? await fetch(
            `https://trakky.in:8000/spavendor/offer-request/${editProductData.id}/`,
            {
              // Use the appropriate ID for updates
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
              },
              body: JSON.stringify(payload),
            }
          )
        : await fetch(
            "https://trakky.in:8000/spavendor/offer-request/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
              },
              body: JSON.stringify(payload),
            }
          );

      if (response.ok) {
        const data = await response.json();
        toast.success(
          editProductData
            ? "Offer Request Updated Successfully"
            : "Offer Request Created Successfully"
        );
        // Update the state in OffersRequest if necessary
        refreshData();
        setTimeout(() => {
          handleClose();
        }, 1000);
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
      toast.error(
        "An error occurred while creating/updating the offer request"
      );
    }
  };

  return (
    <>
      <div className="w-full max-h-[95vh] overflow-auto max-w-[768px] p-8 bg-white shadow-lg rounded-lg">
        <div className="pb-2 flex justify-between items-center">
          <p className="text-xl font-bold text-center">Add Offer Request</p>
          <span onClick={handleClose} className="cursor-pointer">
            <CloseIcon />
          </span>
        </div>
        <div className="space-y-4 mt-3">
          {/* Offer Name */}
          <div className="flex gap-4">
            <TextField
              label="Offer Name"
              variant="outlined"
              fullWidth
              name="offer_name"
              value={formData.offer_name}
              onChange={handleChange}
            />
            {/* Gender Selection */}
            <TextField
              select
              label="Offer type"
              variant="outlined"
              fullWidth
              name="offer_type"
              value={formData.offer_type}
              onChange={handleChange}
            >
              {/* <MenuItem value="male">Male</MenuItem> */}
              <MenuItem value="general">General</MenuItem>
              <MenuItem value="massage specific">Massage Specific</MenuItem>
            </TextField>
          </div>

          {formData?.offer_type === "massage specific" && (
            <div className="flex gap-4">
              <FormControl fullWidth>
                <InputLabel id="service-select-label">
                  Select Services
                </InputLabel>
                <Select
                  labelId="service-select-label"
                  id="service-select"
                  onChange={(e) => {
                    setSelectedServices(e.target.value);
                    setFormData((prevData) => ({
                      ...prevData,
                      massage_price: allServices.find(
                        (service) => service.id === e.target.value
                      )?.price,
                    }));
                  }}
                  input={<OutlinedInput label="Select Services" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    const service = allServices.find(
                      (service) => service.id === selected
                    );
                    return service?.service_names;
                  }}
                  disabled={serviceLoading}
                >
                  {allServices.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.service_names}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Massage Price"
                variant="outlined"
                fullWidth
                name="massage_price"
                value={formData?.massage_price || ""}
                type="number"
                disabled
              />
            </div>
          )}

          <div className="flex gap-4 items-center">
            <TextField
              label="Discount Percentage"
              variant="outlined"
              fullWidth
              name="offer_percentage"
              value={formData.offer_percentage}
              onChange={handleChange}
              type="number"
            />
            {formData?.offer_type === "massage specific" ? (
              <div className=" h-full w-full items-center  ">
                <span className=" text-gray-500 text-sm font-semibold">
                  Price after offer:{" "}
                </span>
                <span>
                  {formData?.massage_price && formData?.offer_percentage
                    ? (
                        formData?.massage_price -
                        (formData?.massage_price * formData?.offer_percentage) /
                          100
                      ).toFixed(2)
                    : ""}
                </span>
              </div>
            ) : formData?.offer_type === "general" ? (
              <div className=" h-full w-full items-center  ">
                <span className=" text-gray-500 text-sm font-medium">
                  *Applicable on all services
                </span>
              </div>
            ) : (
              <div className=" h-full w-full items-center  "></div>
            )}
          </div>
          {/* Terms and Conditions */}
          <div className=" col-span-6 w-full">
            <label
              htmlFor="editor"
              className=" font-normal pb-2  block"
            >
              Terms and Conditions
            </label>
            <div id="editor" style={{ width: "100%", height: "100px" }}></div>
          </div>
          <div className="flex gap-4 items-center">
            <TextField
              label="Coupon Code (Optional)"
              variant="outlined"
              fullWidth
              name="coupon_code"
              value={formData.coupon_code}
              onChange={handleChange}
              type="text"
            />
            <TextField
              label="How to avail"
              variant="outlined"
              fullWidth
              name="how_to_avail"
              value={formData.how_to_avail}
              onChange={handleChange}
              type="text"
            />
          </div>
           {/* Submit Button */}
          <div className="flex justify-between gap-2 mt-6">
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
              onClick={handleSubmit}
            >
              {/* Add Offer Request */}
              {editProductData ? "Update Offer Request" : "Add Offer Request"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
