import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/Auth";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import toast, { Toaster } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

function DailySheet({ date }) {
  const { authTokens } = useContext(AuthContext);
  const [staffData, setStaffData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formatisedData, setFormatisedData] = useState([]);

  // const handleStaffAttendance = (id, data) => {
  //   setLoading(true);
  //   if (
  //     data.date !==
  //     new Date()
  //       .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
  //       .slice(0, 10)
  //   ) {
  //     alert("You can only edit today's attendance");
  //     return;
  //   }
  //   if (!id && typeof data.present === "undefined") {
  //     data.present = false;
  //   }

  //   fetch(
  //     `https://trakky.in:8000/spavendor/staff/attendance/${
  //       id ? id + "/" : ""
  //     }`,
  //     {
  //       method: id ? "PATCH" : "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authTokens?.access_token}`,
  //       },
  //       body: JSON.stringify(data),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data) {
  //         if (!id) {
  //           if (data.non_field_errors) {
  //             console.log(data.non_field_errors);
  //           } else if (data.error) {
  //             console.log(data.error);
  //             alert(data.error);
  //           } else {
  //             setStaffDailyData([...staffDailyData, data]);
  //           }
  //         } else {
  //           setStaffDailyData(
  //             staffDailyData.map((staffDaily) =>
  //               staffDaily.id === data.id ? data : staffDaily
  //             )
  //           );
  //         }
  //       }
  //     })
  //     .catch((err) => console.log(err));

  //   setLoading(false);
  // };

  const getStaffData = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/staff/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );
      const data = await response.json();
      setStaffData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getStaffAttendanceData = async () => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/staff/attendance/?date=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );
      const data = await response.json();
      setAttendanceData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStaffData();
    getStaffAttendanceData();
  }, [authTokens, date]);

  useEffect(() => {
    if (staffData?.length > 0) {
      const formatiseD = staffData.map((staff) => {
        const staffAttendance = attendanceData.find(
          (attendance) => attendance.staff === staff.id
        );
        if (staffAttendance) {
          return {
            id: staff.id,
            staffname: staff.staffname,
            present: staffAttendance.present,
            num_services: staffAttendance.num_services,
            time_in: staffAttendance.time_in,
            time_out: staffAttendance.time_out,
            attendance_id: staffAttendance.id,
          };
        } else {
          return {
            id: staff.id,
            staffname: staff.staffname,
            present: false,
            num_services: 0,
            time_in: "-",
            time_out: "-",
            attendance_id: null,
          };
        }
      });

      setFormatisedData(formatiseD);
    }
  }, [attendanceData, staffData]);

  const [editOpen, setEditOpen] = useState(false);
  const [editPresent, setEditPresent] = useState(false);
  const [editTimeIn, setEditTimeIn] = useState(null);
  const [editTimeOut, setEditTimeOut] = useState(null);
  const [editAttendanceId, setEditAttendanceId] = useState(null);
  const [editStaffData, setEditStaffData] = useState(null);

  const handleEditClose = () => {
    setEditOpen(false);
    setEditPresent(null);
    setEditTimeIn("");
    setEditTimeOut("");
    setEditAttendanceId(null);
    setEditStaffData(null);
  };

  const handleStaffAttendence = async () => {
    let errors = [];

    
    let formatedTimeIn = editTimeIn?.format("HH:mm:ss");
    let formatedTimeOut = editTimeOut?.format("HH:mm:ss");

    console.log(formatedTimeIn, formatedTimeOut); // "Invalid Date" or "HH:mm:ss"

    // if present is true, then time in must be provided
    // if time out is provided, then time in must be provided
    if (editPresent && (formatedTimeIn == "Invalid Date")) {
      errors.push("Time in is required");
    }

    if (formatedTimeOut != "Invalid Date" && formatedTimeIn == "Invalid Date") {
      errors.push("Time in is required");
    }


    // if absent, then time in and time out must be null
    if (!editPresent && (formatedTimeIn != "Invalid Date" || formatedTimeOut != "Invalid Date")) {
      errors.push("Time in and time out must be null");
    }


    

   


  
    if (errors.length > 0) {
      // Show all errors in a toast notification
      toast.error(errors.join(" "));
      return;
    }

    let payload = {};

    if (editPresent) {
      payload = {
        present: editPresent,
        time_in:
          editTimeIn?.format("HH:mm:ss") != "Invalid Date"
            ? editTimeIn.format("HH:mm:ss")
            : null,
        time_out:
          editTimeOut?.format("HH:mm:ss") != "Invalid Date"
            ? editTimeOut.format("HH:mm:ss")
            : null,
        date: date,
        staff: editStaffData?.id,
      };
    } else {
      payload = {
        present: editPresent,
        time_in: null,
        time_out: null,
        date: date,
        staff: editStaffData?.id,
      };
    }

    try {
      let resp = await fetch(
        `https://trakky.in:8000/spavendor/staff/attendance/${
          editAttendanceId ? editAttendanceId + "/" : ""
        }`,
        {
          method: editAttendanceId ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await resp.json();

      if (resp.ok) {
        toast.success("Attendance updated successfully");
        getStaffAttendanceData();
        handleEditClose();
      } else {
        // Collect error messages from response
        if (data.non_field_errors) {
          toast.error(data.non_field_errors.join(" "));
        } else {
          toast.error("You can only update today's attendance.");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to update attendance");
    }
  };

  return (
    <div className="  w-full pb-2 px-4  max-w-[100vw] md:max-w-[calc(100vw-72px)] overflow-auto mt-7 lg:px-10">
      <Toaster />
      <table className=" border-collapse w-full bg-white rounded-lg text-center min-w-max">
        <tr>
          <th className=" border border-gray-200 p-2">S.No</th>
          <th className=" border border-gray-200 p-2">Staff Name</th>
          <th className=" border border-gray-200 p-2">Present</th>
          <th className=" border border-gray-200 p-2">No. Services</th>
          {/* <th className=" border border-gray-200 p-2">Commission</th> */}
          <th className=" border border-gray-200 p-2">Time In</th>
          <th className=" border border-gray-200 p-2">Time Out</th>
          <th className=" border border-gray-200 p-2">Actions</th>
        </tr>

        {loading ? (
          <tr className=" h-40 ">
            <td colSpan="7" className=" mx-auto">
              {" "}
              <CircularProgress
                sx={{
                  color: "#000",
                  margin: "auto",
                }}
              />
            </td>
          </tr>
        ) : formatisedData?.length > 0 ? (
          formatisedData?.map((item, index) => (
            <tr>
              <td className=" border border-gray-200 p-2">{index + 1}</td>
              <td className=" border border-gray-200 p-2">{item?.staffname}</td>
              <td className=" border border-gray-200 p-2">
                {item?.present ? "Yes" : "No"}
              </td>
              <td className=" border border-gray-200 p-2">
                {item?.num_services}
              </td>
              <td className=" border border-gray-200 p-2">
                {item?.time_in ?? "-"}
              </td>
              <td className=" border border-gray-200 p-2">
                {item?.time_out ?? "-"}
              </td>
              <td className=" border border-gray-200 p-2">
                <IconButton
                  onClick={() => {
                    setEditOpen(true);
                    setEditPresent(item?.present);
                    setEditTimeOut(dayjs(item?.time_out, "HH:mm:ss"));
                    setEditTimeIn(dayjs(item?.time_in, "HH:mm:ss"));
                    setEditAttendanceId(item?.attendance_id);
                    setEditStaffData(item);
                  }}
                  style={{ color: "black" }}
                >
                  <EditIcon />
                </IconButton>
              </td>
            </tr>
          ))
        ) : (
          <tr className=" h-40 text-center">
            <td colSpan="7">No staff Data</td>
          </tr>
        )}
      </table>
      <Modal
        open={editOpen}
        onClose={handleEditClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "450px",
            padding: "25px",
            maxWidth: "-webkit-fill-available",
            display: "flex",
            flexFlow: "column",
            gap: "15px",
          }}
        >
          <span className="w-full flex gap-[15px]">
            <TextField
              id="outlined-basic"
              label="Staff name"
              sx={{ width: "50%" }}
              variant="outlined"
              value={editStaffData?.staffname}
              readOnly
            />
            <div className=" w-1/2 h-full">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Present/Absent
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Membership used"
                  value={editPresent}
                  onChange={(e) =>  {
                    if (e.target.value == true) {
                      setEditPresent(true)
                    } else {
                      setEditPresent(false);
                      setEditTimeIn(null);
                      setEditTimeOut(null);
                    }
                  }}
                >
                  <MenuItem value={true}>Present</MenuItem>
                  <MenuItem value={false}>Absent</MenuItem>
                </Select>
              </FormControl>
            </div>
          </span>
          <span className="w-full flex gap-[15px]">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                width: "100%",
              }}
            >
              <TimePicker
                label="Time In"
                value={editTimeIn ? editTimeIn : null}
                onChange={setEditTimeIn}
                referenceDate={editStaffData?.date}
                sx={{
                  width: "100%",
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                width: "100%",
              }}
            >
              <TimePicker
                label="Time Out"
                value={editTimeOut ? editTimeOut : null}
                onChange={setEditTimeOut}
                referenceDate={editStaffData?.date}
                sx={{
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </span>
          <button
            className="w-full bg-[blue] text-white py-[8px] rounded-xl"
            onClick={handleStaffAttendence}
          >
            Save
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default DailySheet;
