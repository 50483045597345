import React, { cloneElement } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralModal from "../generalModal/GeneralModal";
import DateRange from "../dateModal/DateRange";
import { Switch } from "@mui/material";

const AppointmentToggle = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showDateSelectionModal, setShowDateSelectionModal] = useState(false);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <>
      <div className="bg-[#EFECFF] w-full h-[calc(100vh-52px)] esm:h-[calc(100vh-70px)] esm:pl-[72px]">
        <div className="h-[64px] w-full p-3 lg:pl-10 flex flex-wrap lg:flex-nowrap justify-center md:justify-between items-center mb-10 gap-2 md:gap-0 lg:mb-0">
          <div className="flex items-center h-full lg:w-fit bg-[#CACFFF] rounded-lg mb-2 lg:mb-0">
            <button
              className={`h-full rounded-lg px-3 py-1 transition-all duration-500w-1/2 esm:w-1/3 md:w-auto text-xs sm:text-sm md:text-base lg:w-auto ${
                location.pathname.split("/").includes("list-appointment")
                  ? "bg-white shadow-md"
                  : "bg-transparent cursor-pointer"
              }`}
              onClick={() => {
                navigate("/appointment/list-appointment/calender");
              }}
            >
              Appointment
            </button>
            <button
              className={`h-full rounded-lg px-3 py-1 transition-all duration-500 esm:w-1/3 md:w-auto text-xs sm:text-sm md:text-base lg:w-auto ${
                location.pathname.split("/").includes("create-appointment")
                  ? "bg-white shadow-md "
                  : "bg-transparent cursor-pointer"
              }`}
              onClick={() => {
                navigate("/appointment/create-appointment");
              }}
            >
              Create Appointment
            </button>
          </div>
          {/* switch toggle between calender & card view [ calender ( switch ) card ] */}
          {
            location.pathname.includes("list-appointment") && 
            <div 
              >
                <span>Calender</span>
                <Switch  
                  checked={location.pathname.includes("calender") ? false : true}
                  onChange={() => {
                    if (location.pathname.includes("calender")) {
                      navigate(location.pathname.replace("calender", "card"));
                    } else {
                      navigate(location.pathname.replace("card", "calender"));
                    }
                  }}
                />
                <span>Card</span>
              </div>
          }

          {/* date filter for /card */}
          { <div
            className={`px-4 min-h-[2rem] relative shadow-xl text-center rounded-lg flex items-center bg-white cursor-pointer border-none
                ${location.pathname.includes("card") ? "visible" : "invisible"}
              `}
            onClick={() => {
              setShowDateSelectionModal(true);
            }}
          >
            <input
              type="text"
              value={`${dateState[0].startDate.getDate()}-${
                dateState[0].startDate.getMonth() + 1
              }-${dateState[0].startDate.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
              className="cursor-pointer outline-none border-none focus:outline-none"
            />
            <span style={{ padding: "0 10px" }}> ~ </span>
            <input
              type="text"
              value={`${dateState[0]?.endDate?.getDate()}-${
                dateState[0]?.endDate?.getMonth() + 1
              }-${dateState[0]?.endDate?.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
              className="cursor-pointer outline-none border-none focus:outline-none"
            />
          </div>}
        </div>
        {cloneElement(props.children, {
          startDate: `${dateState[0].startDate.getFullYear()}-${
            dateState[0].startDate.getMonth() + 1
          }-${dateState[0].startDate.getDate()}`,
          endDate: `${dateState[0].endDate.getFullYear()}-${
            dateState[0].endDate.getMonth() + 1
          }-${dateState[0].endDate.getDate()}`,
        })}
      </div>
      <GeneralModal
        open={showDateSelectionModal}
        handleClose={() => setShowDateSelectionModal(false)}
      >
        <DateRange
          dateState={dateState}
          setDateState={setDateState}
          setShowDateSelectionModal={setShowDateSelectionModal}
        />
      </GeneralModal>
    </>
  );
};

export default AppointmentToggle;
