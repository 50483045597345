import React, { cloneElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CreateMembership = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className=" h-full w-full bg-[#EFECFF]">
      <div className=" h-[64px] w-full  p-3">
        <div className=" flex items-center h-full w-fit bg-[#CACFFF] rounded-lg">
          <button
            className={`h-full rounded-lg px-3 transition-all duration-500 ${
              location.pathname
                .split("/")
                .includes("create-customer-membership")
                ? "bg-white shadow"
                : "bg-transparent cursor-pointer"
            }`}
            onClick={() => {
              navigate("/catalogue/create-customer-membership");
            }}
          >
            Customer membership
          </button>
          <button
            className={`h-full rounded-lg px-3 transition-all duration-500 ${
              location.pathname.split("/").includes("create-membership-type")
                ? "bg-white shadow"
                : "bg-transparent cursor-pointer"
            }`}
            onClick={() => {
              navigate("/catalogue/create-membership-type");
            }}
          >
            Membership type
          </button>
        </div>
      </div>
      {cloneElement(props?.children)}
    </div>
  );
};

export default CreateMembership;
