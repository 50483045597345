import React, { useContext, useState, useEffect } from "react";
import "./catalogue.css";
import MiniHeader from "./MiniHeader";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../Context/Auth";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete, Edit, InfoOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CreateMembershipType from "./form/CreateMembershipType";

const MembershipType = () => {
  const { authTokens } = useContext(AuthContext);
  const confirm = useConfirm();
  const [membershipTypes, setMembershipTypes] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");
  const [modalMembershipType, setModalMembershipType] = useState(null); // State to store data for the modal
  const [openPointPerMassage, setOpenPointPerMassage] = useState(false);
  const [pointPerMassageItem, setPointPerMassageItem] = useState(null);

  const fetchMembershipTypes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/membership-type-new/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMembershipTypes(data);
        setFilterData(data);
      } else {
        toast.error(data.detail);
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const deleteMembershipType = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete this membership type?`,
      });

      const response = await fetch(
        `https://trakky.in:8000/spavendor/membership-type-new/${id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      if (response?.ok) {
        toast.success("Membership type deleted successfully");
        fetchMembershipTypes(); // Refresh data after deletion
      } else {
        toast.error("Failed to delete membership type: ", response.statusText);
      }
    } catch (error) {
      if (error === undefined || error === "cancel") {
        console.log("Deletion cancelled");
        return;
      }

      toast.error("An error occurred" + error);
    }
  };

  const handleOpen = (membershipType) => {
    setModalMembershipType(membershipType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalMembershipType(null);
    fetchMembershipTypes();
  };

  useEffect(() => {
    fetchMembershipTypes();
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilterData(membershipTypes);
    } else {
      // setFilterData(
      //   membershipTypes?.filter((membershipType) =>
      //     membershipType?.name?.toLowerCase().includes(search.toLowerCase())
      //   )
      // );
      setFilterData(
        membershipTypes?.filter((membershipType) =>
          membershipType?.membership_name?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, membershipTypes]);

  return (
    <div className="w-full h-full bg-[#EFECFF]">
      <ToastContainer />
      <MiniHeader title="Membership type" />
      <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
        <div className="w-full h-full flex flex-col gap-2">
          <div className="w-full h-14 px-3 flex py-2 gap-2 shrink-0">
            <input
              type="text"
              name="search"
              id="search"
              className="shrink grow h-full w-full max-w-[min(100%,400px)] rounded-xl outline-none active:outline-none focus:outline-none px-4"
              placeholder="membership name..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="bg-[#512DC8] h-full w-20 flex items-center justify-center text-center text-sm text-white rounded-xl border-2 border-[#EFECFF]"
              onClick={() => {
                setSearch("");
              }}
            >
              Reset
            </button>
          </div>
          <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-auto">
            <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
              <thead>
                <tr>
                  <th className="border border-gray-200 p-2">S/N</th>
                  <th className="border border-gray-200 p-2">
                    Membership name
                  </th>
                  <th className="border border-gray-200 p-2">
                    Membership price
                  </th>
                  <th className="border border-gray-200 p-2">Total points</th>
                  <th className="border border-gray-200 p-2">
                    Validity (month)
                  </th>
                  <th className="border border-gray-200 p-2">
                    Points per massage
                  </th>
                  <th className="border border-gray-200 p-2">
                    Terms & conditions
                  </th>
                  <th className="border border-gray-200 p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="h-40">
                    <td colSpan="7" className="mx-auto">
                      <CircularProgress
                        sx={{
                          color: "#000",
                          margin: "auto",
                        }}
                      />
                    </td>
                  </tr>
                ) : filterData?.length > 0 ? (
                  filterData?.map((membershipType, index) => (
                    <tr key={membershipType.id}>
                      <td className="border border-gray-200 p-2">
                        {index + 1}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {membershipType.membership_name ?? "No name"}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {membershipType.membership_price ?? "No price"}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {membershipType.total_point ?? "-"}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {membershipType.validity_in_months ?? "-"}
                      </td>
                      <td className="border border-gray-200 p-2 dangerous-html">
                        {/* {membershipType?.service_ids?.map((item, index) => (
                          <div>
                            <span>
                              {item?.name} ( {item?.price} )
                            </span>
                            - {item?.points_per_massage}
                          </div>
                        ))} */}
                        <InfoOutlined
                          onClick={() => {
                            setOpenPointPerMassage(true);
                            setPointPerMassageItem(membershipType);
                          }}
                        />
                      </td>
                      <td className="border border-gray-200 p-2 max-w-[300px] dangerous-html">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: membershipType.terms_and_conditions,
                          }}
                          className="dangerous-html w-full"
                        ></div>
                      </td>
                      <td className="border border-gray-200">
                        <div className="flex items-center justify-center h-full gap-2">
                          <button onClick={() => handleOpen(membershipType)}>
                            <Edit />
                          </button>
                          <button
                            onClick={() =>
                              deleteMembershipType(membershipType.id)
                            }
                          >
                            <Delete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="h-40 text-center">
                    <td colSpan="7">No membership types found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              maxWidth: "800px",
              width: "100%",
              border: "none",
              outline: "none",
              padding: "0",
              height: "auto",
              maxHeight: "90vh",
              overflow: "auto",
              borderRadius: "10px",
            }}
          >
            {modalMembershipType && (
              <CreateMembershipType
              editData={modalMembershipType}
                onClose={handleClose}
              />
            )}
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={openPointPerMassage}
        onClose={() => {
          setOpenPointPerMassage(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPointPerMassage}>
          <Box
            sx={{
              ...style,
              maxWidth: "800px",
              width: "100%",
              border: "none",
              outline: "none",
              padding: "0",
              height: "auto",
              maxHeight: "90vh",
              overflow: "auto",
              borderRadius: "10px",
            }}
          >
            <div>
              <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
                <thead>
                  <tr>
                    <th className="border border-gray-200 p-2">S/N</th>
                    <th className="border border-gray-200 p-2">Service name</th>
                    <th className="border border-gray-200 p-2">Price</th>
                    <th className="border border-gray-200 p-2">
                      Points per massage
                    </th>
                  </tr>
                </thead>
                <tbody>
               
                  {pointPerMassageItem?.service_ids?.map((item, index) => (
                    <tr>
                      <td className="border border-gray-200 p-2">
                        {index + 1}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {item.name}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {item.price}
                      </td>
                      <td className="border border-gray-200 p-2">
                        {item.points_per_massage}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default MembershipType;
