import React from 'react'

const Nopage = () => {
  return (
    <div className='w-full flex justify-center'>
        Nothing found
    </div>
  )
}

export default Nopage
