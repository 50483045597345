import React, { useContext } from "react";
import AuthContext from "../../Context/Auth";
import { Box, Modal, TextField } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DailyExpensesBody = ({ startDate, endDate }) => {
  const { authTokens } = useContext(AuthContext);
  //   const [expenseDate, setExpenseDate] = useState(dayjs());
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [paid_to, setPaid_to] = useState("");
  const [paid_from, setPaid_from] = useState("");
  const [dailyExpensData, setDailyExpensData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [editDailyExpense, setEditDailyExpense] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    if (!authTokens) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/daily-expensis/?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${authTokens.access_token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setDailyExpensData(responseData);
      } else {
        toast.error("Error while fetching data");
      }
    } catch (error) {
      toast.error("Error while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditDailyExpense(null);
  }

  const handlePOSTdailyExpenses = async () => {
    const expenseData = {
      name,
      amount,
      paid_to,
      paid_from,
    };

    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/daily-expensis/",
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authTokens.access_token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(expenseData),
        }
      );
      if (response.ok) {
        toast.success("Expense added successfully");
        setOpen(false);
        setName("");
        setAmount("");
        setPaid_from("");
        setPaid_to("");
        fetchData();
      } else {
        toast.error("Error while adding expense");
      }
    } catch (error) {
      toast.error("Error while adding expense");
    }
  };

  const handlePatchDailyExpenses = async () => {
    const expenseData = {
      name,
      amount,
      paid_to,
      paid_from,
    };

    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/daily-expensis/${editDailyExpense?.id}/`,
        {
          method: "PATCH",
          headers: {
            authorization: `Bearer ${authTokens.access_token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(expenseData),
        }
      );

      if (response.ok) {
        toast.success("Expense updated successfully");
        setOpen(false);
        setName("");
        setAmount("");
        setPaid_from("");
        setPaid_to("");
        fetchData();
        setEditDailyExpense(null);
      } else {
        toast.error("Error while updating expense");
      }
    } catch (error) {
      toast.error("Error while updating expense");
    }
  };


  const handleDeleteExpense = async (id) => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/daily-expensis/${id}/`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authTokens.access_token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response.ok) {
        setDailyExpensData(dailyExpensData.filter((item) => item.id !== id));
        toast.success("Expense Deleted Successfully");
      } else {
        const responseData = await response.json();
        console.log("Error deleting expense", responseData);
        toast.error("Error deleting expense : " + response.statusText);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete expense" + error);
    }
  };

  const reverseFormateStartEndDate = (date) => {
    const dateArray = date.split("-");
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  };

  useEffect(() => {
    let total = 0;
    dailyExpensData.reduce((acc, item) => {
      total += item.amount;
    }, 0);
    setTotalAmount(total);
  }, [dailyExpensData]);

  return (
    <div className=" pt-3 mt-3 px-6 h-[calc(100%-80px)] overflow-auto">
      <Toaster />
      <div className=" flex gap-0 w-full h-auto relative sm:gap-6">
        <div className=" w-[calc(100%-350px)] shrink-0 h-full overflow-auto">
          <table className=" border-collapse w-full bg-white rounded-lg text-center min-w-max">
            <tr>
              <th className=" border border-gray-200 p-2">S.No</th>
              <th className=" border border-gray-200 p-2">Expense amount</th>
              <th className=" border border-gray-200 p-2">Paid to</th>
              <th className=" border border-gray-200 p-2">paid by</th>
              <th className=" border border-gray-200 p-2">Expense details</th>
              <th className=" border border-gray-200 p-2">Date</th>
              <th className=" border border-gray-200 p-2">Action</th>
            </tr>

            {loading ? (
              <tr className=" h-40 ">
                <td colSpan="7" className=" mx-auto">
                  {" "}
                  <CircularProgress
                    sx={{
                      color: "#000",
                      margin: "auto",
                    }}
                  />
                </td>
              </tr>
            ) : dailyExpensData?.length > 0 ? (
              dailyExpensData?.map((item, index) => (
                <>
                  <tr>
                    <td className=" border border-gray-200 p-2">{index + 1}</td>
                   
                    <td className=" border border-gray-200 p-2">
                      {item?.amount}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.paid_to}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.paid_from}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {item?.name}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      {dayjs(item?.created_at).format("DD-MM-YYYY")}
                    </td>
                    <td className=" border border-gray-200 p-2">
                      <IconButton 
                        style={{ color: "green" }}
                        onClick={() => {
                          setEditDailyExpense(item);
                          handleOpen();
                          setAmount(item?.amount);
                          setPaid_to(item?.paid_to);
                          setPaid_from(item?.paid_from);
                          setName(item?.name);

                        }}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => handleDeleteExpense(item?.id)}
                        style={{ color: "red" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr className=" h-40 text-center">
                <td colSpan="7">No expense Data</td>
              </tr>
            )}
          </table>
        </div>
        {/* <div className=" border-r border-dashed h-full min-h-[100%] border-black w-[1px] block" /> */}
        <div className=" w-[350px] h-fit sticky top-0 flex flex-col gap-3 bg-white rounded-md p-2 md:p-4">
          <button
            className="bg-black text-white px-[10px] py-[8px] rounded-md"
            onClick={handleOpen}
          >
            Add Expense
          </button>
          <div className=" flex gap-2 flex-col mt-2">
            <div className=" font-semibold text-base">
              Start Date :{" "}
              <span className=" font-normal">
                {reverseFormateStartEndDate(startDate)}
              </span>
            </div>
            <div className=" font-semibold text-base">
              End Date :{" "}
              <span className=" font-normal">
                {reverseFormateStartEndDate(endDate)}
              </span>
            </div>
          </div>
          <div className=" font-semibold text-lg">
            Total Expenses : <span>&#8377;{totalAmount}</span>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "100%",
            maxWidth: "750px",
            padding: "25px",
            display: "flex",
            flexFlow: "column",
            gap: "15px",
          }}
        >
          <div className=" grid grid-cols-3 max-w-[750px] gap-5 w-full">
            <TextField
              id="outlined-basic"
              label="Expense amount"
              sx={{ width: "50%" }}
              variant="outlined"
              type="number"
              // value={amount}
              // onChange={(e) => setAmount(e.target.value)}
              value={amount}
              className=" !w-full"
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();

                }
              }}
              onChange={(e) => setAmount(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Paid to"
              sx={{ width: "50%" }}
              variant="outlined"
              className=" !w-full"
              value={paid_to}
              onChange={(e) => setPaid_to(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Paid by"
              sx={{ width: "50%" }}
              variant="outlined"
              value={paid_from}
              className=" !w-full"
              onChange={(e) => setPaid_from(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Expense details"
              sx={{ width: "50%" }}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" col-span-3 !w-full"
            />
            <button
              className="w-full bg-[#512bc8] text-white py-[8px] rounded-xl col-span-3"
              onClick={editDailyExpense ? handlePatchDailyExpenses : handlePOSTdailyExpenses}
            >
              {/* Save */}
              {editDailyExpense ? "Update" : "Save"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DailyExpensesBody;
