import React, { useContext, useEffect, useState } from "react";
import Search from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useConfirm } from "material-ui-confirm";
import "./DailySheet.css";
import AuthContext from "../../Context/Auth";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress } from "@mui/material";

import GeneralModal from "../generalModal/GeneralModal";
import RegisterStaff from "./RegisterStaff";
import EditIcon from '@mui/icons-material/Edit';


function StaffRecord({ startDate, endDate }) {
  const { authTokens } = useContext(AuthContext);
  const token = authTokens.access_token;
  const [staffData, setStaffData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalOpenData, setEditModalOpenData] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/staff/?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setStaffData(data);
      } else {
        throw new Error("Error fetching staff data");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch staff data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
 
    fetchData(startDate, endDate);
  }, [startDate, endDate]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/staff/${id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setStaffData(staffData.filter((staff) => staff.id !== id));
        toast.success("Staff Deleted Successfully");
      } else {
        const responseData = await response.json();
        console.log("Error deleting staff", responseData);
        throw new Error("Error deleting staff");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete staff");
    }
  };

  const handleDeleteConfirmation = (id) => {
    confirm({ description: "Are you sure you want to delete this staff?" })
      .then(() => handleDelete(id))
      .catch(() => console.log("Deletion cancelled."));
  };

  const filteredStaffData = staffData.filter((staff) =>
    staff.staffname.toLowerCase().includes(search.toLowerCase())
  );

  return (
    // <div className="table-container">
    //   <div className="w-full flex items-center mb-4 max-md:justify-center">
    //     <TextField
    //       onChange={(e) => setSearch(e.target.value)}
    //       variant="outlined"
    //       placeholder="Search by name..."
    //       sx={{ width: 400, borderRadius: "16px", backgroundColor: "white" }}
    //       InputProps={{
    //         startAdornment: (
    //           <InputAdornment position="start">
    //             <Search />
    //           </InputAdornment>
    //         ),
    //         style: { borderRadius: "16px" },
    //       }}
    //     />
    //   </div>
    //   <div className="sheetTableContainer">
    //     <table>
    //       <thead>
    //         <tr>
    //           <th>Staff Name</th>
    //           <th>Status</th>
    //           <th>Contact no.</th>
    //           <th>Address</th>
    //           <th>Salary</th>
    //           <th>Amount Paid</th>
    //           <th>Id proof</th>
    //           <th>Joining Date</th>
    //           <th>Email</th>
    //           <th>Gender</th>
    //           <th>Action</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {loading ? (
    //           <tr>
    //             <td colSpan="11">Loading...</td>
    //           </tr>
    //         ) : filteredStaffData.length === 0 ? (
    //           <tr>
    //             <td colSpan="11">No records found</td>
    //           </tr>
    //         ) : (
    //           filteredStaffData.map((staff) => (
    //             <tr key={staff.id}>
    //               <td>{staff.staffname}</td>
    //               <td>
    //                 {staff.is_permanent === true ? "Permanent" : "Temporary"}
    //               </td>
    //               <td>
    //                 {staff.ph_number ? staff.ph_number : "Not Provided"}
    //               </td>
    //               <td>{staff.address ? staff.address : "Not provided"}</td>
    //               <td>{staff.salary ? staff.salary : "Not provided"}</td>
    //               <td>
    //                 {staff.amount_paid ? staff.amount_paid : "Not provided"}
    //               </td>
    //               <td className="flex justify-center items-center">
    //                 {staff.id_proof ? (
    //                   <img
    //                     src={staff.id_proof}
    //                     alt=""
    //                     className="w-[40px] h-auto"
    //                   />
    //                 ) : (
    //                   "Not provided"
    //                 )}
    //               </td>
    //               <td>
    //                 {staff.joining_date ? staff.joining_date : "Not provided"}
    //               </td>
    //               <td>{staff.email ? staff.email : "Not provided"}</td>
    //               <td>{staff.gender ? staff.gender : "Not provided"}</td>
    //               <td>
    //                 <IconButton
    //                   onClick={() => handleDeleteConfirmation(staff.id)}
    //                   style={{ color: "red" }}
    //                 >
    //                   <DeleteIcon />
    //                 </IconButton>
    //               </td>
    //             </tr>
    //           ))
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    //   <Toaster position="top-center" reverseOrder={false} />
    // </div>
    <>
      <div className="  w-full pb-2 px-4  max-w-[100vw] md:max-w-[calc(100vw-72px)] overflow-auto mt-7 lg:px-10">
        <table className=" border-collapse w-full bg-white rounded-lg text-center min-w-max">
          <tr>
            <th className=" border border-gray-200 p-2">S.No</th>
            <th className=" border border-gray-200 p-2">Staff Name</th>
            <th className=" border border-gray-200 p-2">Status</th>
            <th className=" border border-gray-200 p-2">Contact no.</th>
            <th className=" border border-gray-200 p-2">Email</th>
            <th className=" border border-gray-200 p-2">Gender</th>
            <th className=" border border-gray-200 p-2">Appointments</th>
            <th className=" border border-gray-200 p-2">Attendence</th>
            <th className=" border border-gray-200 p-2 max-w-60 text-wrap">
              Address
            </th>
            <th className=" border border-gray-200 p-2">Salary</th>
            <th className=" border border-gray-200 p-2">ID proff</th>
            <th className=" border border-gray-200 p-2">Joining Date</th>
            <th className=" border border-gray-200 p-2">Action</th>
          </tr>

          {loading ? (
            <tr className=" h-40 ">
              <td colSpan="11" className=" mx-auto">
                {" "}
                <CircularProgress
                  sx={{
                    color: "#000",
                    margin: "auto",
                  }}
                />
              </td>
            </tr>
          ) : staffData?.length > 0 ? (
            staffData?.map((item, index) => (
              <tr>
                <td className=" border border-gray-200 p-2">{index + 1}</td>
                <td className=" border border-gray-200 p-2">
                  {item?.staffname}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.is_permanent ? "Permanent" : "Temporary"}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.ph_number ? item?.ph_number : "Not Provided"}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.email ? item?.email : "Not Provided"}
                </td>
                <td className=" border border-gray-200 p-2">{item?.gender}</td>
                <td className=" border border-gray-200 p-2">
                  {item?.appointments ? item?.appointments : "N/A"}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.staff_attendance ? item?.staff_attendance : "N/A"}
                </td>

                <td className=" border border-gray-200 p-2 max-w-60">
                  {item?.address ? item?.address : "Not Provided"}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.salary ? item?.salary : "Not Provided"}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.id_proof ? (
                    <img
                      src={item?.id_proof}
                      alt=""
                      className="w-[40px] h-auto  mx-auto"
                    />
                  ) : (
                    "Not Provided"
                  )}
                </td>
                <td className=" border border-gray-200 p-2">
                  {item?.joining_date ? item?.joining_date : "Not Provided"}
                </td>
                <td className=" border border-gray-200 p-2">
                  <IconButton
                    onClick={() => {
                      setEditModalOpenData(item);
                      setEditModalOpen(true);
                    }}
                    style={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteConfirmation(item?.id)}
                    style={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))
          ) : (
            <tr className=" h-40 text-center">
              <td colSpan="11">No staff Data</td>
            </tr>
          )}
        </table>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      <GeneralModal
        open={editModalOpen}
        handleClose={() => {
          setEditModalOpenData({});
          setEditModalOpen(false);
        }}
      >
        <RegisterStaff staffData={editModalOpenData} 
          handleClose={() => {
            setEditModalOpenData({});
            setEditModalOpen(false);
          }}
          fetchData={fetchData}
        />
      </GeneralModal>
    </>
  );
}

export default StaffRecord;
