import React, { useState, useContext, useEffect } from 'react';
import { TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthContext from "../Context/Auth";
import toast, { Toaster } from "react-hot-toast";
import "./Settings.css";

const ChangePassword = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  
  const handleMouseDownPassword = (event) => event.preventDefault();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://trakky.in:8000/spavendor/vendor/${user.user_id}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const jsonData = await response.json();
        setUserData(jsonData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Failed to load user data');
      }
    };
    fetchData();
  }, [user.user_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!userData.ph_number || !oldPassword || !newPassword) {
      toast.error('Phone number, current password, and new password are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password do not match.');
      return;
    }
  
    const formData = new FormData();
    formData.append("ph_number", userData.ph_number);
    formData.append("password", oldPassword);
    formData.append("new_password", newPassword);
  
    try {
      const response = await fetch('https://trakky.in:8000/spavendor/update-password/', {
        method: 'POST',
        body: formData,
      });
  
      // Log the raw response text
      const responseText = await response.text();
      console.log('Raw response:', responseText); // Log the raw response
  
      // Check if the response is okay and parse it
      if (!response.ok) {
        throw new Error('Failed to update password');
      }
      const responseData = JSON.parse(responseText); // Parse the response as JSON
  
      toast.success('Password updated successfully');
      logoutUser();
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error(error.message || 'An error occurred while updating the password');
    }
  };
  

  return (
    <>
      <Toaster/>
      <div className="settingsPOS" style={{ paddingBlock: "30px", overflow: 'auto' }}>
        <div className="formMyinfoPOS_setting">
          <h2>Change Password</h2>

          {/* Phone Number Field */}
          <span className="Input_fieldPOS_settings">
            <FormControl variant="outlined" className="w-[400px]">
              <InputLabel htmlFor="outlined-adornment-phone-number">Phone Number</InputLabel>
              <OutlinedInput
                value={userData.ph_number || "Not Provided"}
                id="outlined-adornment-phone-number"
                type="text"
                label="Phone Number"
                readOnly
                variant="outlined"
                focused={true}
              />
            </FormControl>
          </span>

          {/* Old Password Field */}
          <span className="Input_fieldPOS_settings">
            <FormControl variant="outlined" className="w-[400px]">
              <InputLabel htmlFor="outlined-adornment-old-password">Old Password</InputLabel>
              <OutlinedInput
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                id="outlined-adornment-old-password"
                type={showOldPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle old password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="Old Password"
              />
            </FormControl>
          </span>

          {/* New Password Field */}
          <span className="Input_fieldPOS_settings">
            <FormControl variant="outlined" className="w-[400px]">
              <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
              <OutlinedInput
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="outlined-adornment-new-password"
                type={showNewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
          </span>

          {/* Confirm New Password Field */}
          <span className="Input_fieldPOS_settings">
            <FormControl variant="outlined" className="w-[400px]">
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm New Password</InputLabel>
              <OutlinedInput
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
              />
            </FormControl>
          </span>

          {/* Submit Button */}
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{ marginTop: '20px', width: '100px', background:'black', padding: '0 10px', borderRadius:'8px', fontSize: '16px', cursor:'pointer' }}
            className='btn_SaveEditProfilePOS'
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;