import React from 'react'

const DashClient = () => {
  return (
    <div>
      DashClient
    </div>
  )
}

export default DashClient
