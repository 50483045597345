import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../Context/Auth";
import MiniHeader from "./MiniHeader";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Edit } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import dayjs from "dayjs";
import moment from "moment";
import { motion } from "framer-motion";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import toast, { Toaster } from "react-hot-toast";
import { IconButton } from "@mui/material";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const MassagesRequest = () => {
  const [massageRequestData, setMassageRequestData] = useState([]);
  const [massageRequestDataLoading, setMassageRequestDataLoading] =
    useState(true);
  const [editMassageData, setEditMassageData] = useState(null);
  const { authTokens } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const confirm = useConfirm();

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleOpen = (productData) => {
    if (productData) {
      setEditMassageData(productData); // Set the data to be edited
    } else {
      setEditMassageData(null); // Reset for adding a new massage
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days != 0) {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating != 0) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours != 0) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes != 0) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  const featchMassageRequest = async () => {
    setMassageRequestDataLoading(true);
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/massage-request/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access_token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setMassageRequestData(data);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setMassageRequestDataLoading(false);
    }
  };

  useEffect(() => {
    featchMassageRequest();
  }, []);

  const handleEditClick = (item) => {
    setEditMassageData(item);
    setOpen(true); // Open the modal
  };

  const handleDeleteClick = async (id) => {
    confirm({
      description: "Are you sure you want to delete this massage request?",
    }).then(async () => {
      try {
        const response = await fetch(
          `https://trakky.in:8000/spavendor/massage-request/${id}/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Massage Request Deleted Successfully");
          setMassageRequestData((prevData) =>
            prevData.filter((item) => item.id !== id)
          );
        } else {
          const error = await response?.json();
          toast.error(error.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting the massage request");
      }
    }).catch(() => {});
    
  };

  return (
    <>
      <div className="w-full h-full bg-[#EFECFF]">
        <Toaster />
        <div class="flex items-center justify-between h-14 w-full md:h-16 px-2 md:px-6">
          <div class="h-5 w-5 flex items-center md:hidden">
            <ArrowBackIcon className="w-full" />
          </div>
          <div>
            <h1 class="text-lg font-bold md:text-xl">Massage request</h1>
          </div>
          {window.innerWidth > 768 ? (
            <div class="flex gap-3 items-center">
              <button
                class="rounded-md bg-black text-white px-4 py-2 text-sm"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpen(null);
                }}
              >
                Add massage request
              </button>
            </div>
          ) : (
            <div className="flex gap-2 items-center">
              <button className="rounded-md border border-gray-300 p-1 ">
                <MoreVertIcon className="h-5 w-5" />
              </button>
              <button
                className="rounded-md border border-gray-300 p-1"
                onClick={() => {
                  handleOpen(null);
                }}
              >
                <AddIcon className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>

        <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
          <div className="w-full h-full flex flex-col gap-2">
            <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-y-auto overflow-x-auto">
              <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
                <thead>
                  <tr>
                    <th className="border border-gray-100 p-2">Sr no.</th>
                    <th className="border border-gray-100 p-2">Massage name</th>
                    <th className="border border-gray-100 p-2">From master</th>
                    <th className="border border-gray-100 p-2">Price</th>
                    <th className="border border-gray-100 p-2">Discount</th>
                    <th className="border border-gray-100 p-2">Timing</th>
                    <th className="border border-gray-100 p-2">
                      Description
                    </th>
                    <th className="border border-gray-100 p-2">Status</th>
                    <th className="border border-gray-100 p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {massageRequestDataLoading ? (
                    <tr className="h-40">
                      <td colSpan="11" className="mx-auto">
                        <CircularProgress
                          sx={{
                            color: "#000",
                            margin: "auto",
                          }}
                        />
                      </td>
                    </tr>
                  ) : massageRequestData?.length > 0 ? (
                    massageRequestData?.map((item, index) => {
                      return (
                        <tr key={item?.id}>
                          <td className="p-2 border-gray-200 border">
                            {index + 1}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {item?.service_name || "N/A"}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {item?.from_masterservice ? "Yes" : "No"}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {item?.price || "-"}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {item?.discounted_price || "-"}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {formateTime(item?.massage_time) || "-"} 
                          </td>
                          <td className="p-2 border-gray-200 border">
                            <div
                             className="dangerous-html"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </td>
                          <td className="border border-gray-200 px-2">
                            {item?.service_status === "pending" ? (
                              <span className=" text-blue-600 bg-blue-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Pending
                              </span>
                            ) : item?.service_status === "approved" ? (
                              <span className=" text-emerald-600 bg-emerald-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Approved
                              </span>
                            ) : item?.service_status === "rejected" ? (
                              <span className=" text-red-600 bg-red-200 py-1 px-3 text-sm rounded-md leading-5 block w-fit mx-auto">
                                Rejected
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="p-2 border-gray-200 border">
                            {
                              <button
                                onClick={() => handleEditClick(item)}
                                className={`${
                                  !(item?.service_status === "pending")
                                    ? "invisible"
                                    : "visible"
                                }`}
                              >
                                <BootstrapTooltip title="Edit" placement="top">
                                  <EditIcon />
                                </BootstrapTooltip>
                              </button>
                            }
                            <button onClick={() => handleDeleteClick(item.id)}>
                              <BootstrapTooltip title="Delete" placement="top">
                                <Delete />
                              </BootstrapTooltip>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="11" className="p-2">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <motion.div
          initial={{ y: -30, opacity: 0 }} // Start position
          animate={{ y: 0, opacity: 1 }} // End position
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <MassageAddPage
            handleClose={handleClose}
            editMassageData={editMassageData}
            refreshData={featchMassageRequest}
          />
        </motion.div>
      </Modal>
    </>
  );
};

export default MassagesRequest;

const MassageAddPage = ({ handleClose, editMassageData, refreshData }) => {
  const { authTokens, vendorData } = useContext(AuthContext);
  const editorRef = useRef(null);
  const [massageRequestData, setMassageRequestData] = useState([]);
  const [formData, setFormData] = useState({
    master_service: "",
    service_name: "",
    price: "",
    discounted_price: "",
    serviceHours: "",
    serviceMinutes: "",
    serviceSeating: "",
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300, // Adjust width for a cleaner look
      },
    },
  };
  const [tempAllMasterServices, setTempAllMasterServices] = useState([]);
  const [allMasterServices, setAllMasterServices] = useState([]);
  const [page, setPage] = useState(1);
  const [masterServiceLoading, setMasterServiceLoading] = useState(true);

  const fetchMasterMassages = async () => {
    setMasterServiceLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/masterservice/?page=${page}`
      );
      const data = await response.json();

      if (response.ok) {
        if (page === 1) {
          setTempAllMasterServices(data?.results);
        } else {
          setTempAllMasterServices([
            ...tempAllMasterServices,
            ...data?.results,
          ]);
        }

        if (data?.next) {
          setPage(page + 1);
        }
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setMasterServiceLoading(false);
    }
  };

  useEffect(() => {
    fetchMasterMassages();
  }, [vendorData?.spa, page]);

  useEffect(() => {
    if (tempAllMasterServices?.length > 0) {
      setAllMasterServices(tempAllMasterServices);
    }
  }, [tempAllMasterServices]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    editorRef.current = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "bullet" }, { list: "ordered" }],
          [{ color: [] }],
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (editMassageData) {
      setFormData({
        master_service: editMassageData.master_service ? editMassageData.master_service : "Other",
        service_name: editMassageData.service_name,
        price: editMassageData.price,
        discounted_price: editMassageData.discounted_price,
        serviceHours: editMassageData?.massage_time?.hours,
        serviceMinutes: editMassageData?.massage_time?.minutes,
        serviceSeating: editMassageData?.massage_time?.seating,
        from_masterservice: editMassageData.from_masterservice,
      });
      editorRef.current.root.innerHTML = editMassageData.description;
    }
  }, [editMassageData]);

  const handleSubmit = async () => {
    const payload = {
      master_service: parseInt(formData.master_service),
      service_name: formData.service_name,
      price: parseInt(formData.price),
      discounted_price: parseInt(formData.discounted_price),
      description: editorRef.current.root.innerHTML,
      massage_time: {
        hours: formData.serviceHours,
        minutes: formData.serviceMinutes,
        seating: formData.serviceSeating,
      },
      spa: vendorData?.spa,
      from_masterservice: formData.master_service == "Other" ? false : true,
    };

    try {
      const response = editMassageData
        ? await fetch(
            `https://trakky.in:8000/spavendor/massage-request/${editMassageData.id}/`,
            {
              // Use the appropriate ID for updates
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
              },
              body: JSON.stringify(payload),
            }
          )
        : await fetch("https://trakky.in:8000/spavendor/massage-request/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
            },
            body: JSON.stringify(payload),
          });

      if (response.ok) {
        const data = await response.json();
        toast.success(
          editMassageData
            ? "Massage Request Updated Successfully"
            : "Massage Request Created Successfully"
        );
        // Update the state in MassagesRequest if necessary
        refreshData();
        setTimeout(() => {
          handleClose();
        }, 1000);
      } else if (response.status === 409) {
        const responseData = await response.json();
        toast.error(`conflict: ${responseData.detail}`);
      } else {
        toast.error(`Something went wrong : ${response.status}`);
      }
    } catch (error) {
      toast.error(
        "An error occurred while creating/updating the massage request"
      );
    }
  };

  return (
    <>
      <div className="w-full max-w-[768px] max-h-[95vh] overflow-auto p-8 bg-white shadow-lg rounded-lg">
        <div className="pb-2 flex justify-between items-center">
          <p className="text-xl font-bold text-center">
            { editMassageData ? "Edit Massage Request" : "Add Massage Request"}
            </p>
          <span onClick={handleClose} className="cursor-pointer">
            <CloseIcon />
          </span>
        </div>
        <div className="space-y-4 mt-3">
          <div className="flex gap-4">
            <FormControl fullWidth>
              <InputLabel id="master-service-label">
                Select master service
              </InputLabel>
              <Select
                labelId="master-service-label"
                id="master-service-select"
                MenuProps={MenuProps}
                value={formData.master_service}
                label="Select Services"
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    master_service: e.target.value,
                    service_name:
                      e.target.value === "Other"
                        ? ""
                        : allMasterServices.find(
                            (item) => item.id === e.target.value
                          )?.service_name,
                  }));
                }}
              >
                {allMasterServices?.length > 0 &&
                  allMasterServices?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.service_name}
                    </MenuItem>
                  ))}
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Massage Name"
              variant="outlined"
              fullWidth
              name="service_name"
              value={formData.service_name}
              onChange={handleChange}
              disabled={formData.master_service !== "Other"}
              aria-readonly={formData.master_service !== "Other"}
              // lable on the top if value is not empty
              InputLabelProps={{
                shrink: formData.service_name ? true : false,
              }}
            />
          </div>

          <div className="flex gap-4">
            <TextField
              label="Actual Price"
              variant="outlined"
              fullWidth
              name="price"
              value={formData.price}
              onChange={handleChange}
              type="number"
            />
            <TextField
              label="Discounted Price"
              variant="outlined"
              fullWidth
              name="discounted_price"
              value={formData.discounted_price}
              onChange={handleChange}
              type="number"
            />
          </div>
          {/* Terms and Conditions */}

          <div className=" col-span-6 w-full">
            <label
              htmlFor="editor"
              className=" font-normal pb-2  block"
            ></label>
            <div id="editor" style={{ width: "100%", height: "100px" }}></div>
          </div>
          {/* Service Hours, Minutes, Seating */}
          <div className="flex gap-4">
            <TextField
              label="Service Hours"
              variant="outlined"
              fullWidth
              name="serviceHours"
              value={formData.serviceHours}
              onChange={handleChange}
              type="number"
            />
            <TextField
              label="Service Minutes"
              variant="outlined"
              fullWidth
              name="serviceMinutes"
              value={formData.serviceMinutes}
              onChange={handleChange}
              type="number"
            />
            <TextField
              label="Service Seating"
              variant="outlined"
              fullWidth
              name="serviceSeating"
              value={formData.serviceSeating}
              onChange={handleChange}
              type="number"
            />
          </div>
          {/* Submit Button */}
          <div className="flex justify-between gap-2 mt-6">
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
              onClick={handleSubmit}
            >
              {/* Add Massage Request */}
              {editMassageData ? "Update Massage Request" : "Add Massage Request"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
