import React, { useState, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import "./RegisterStaff.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import AuthContext from "../../Context/Auth";
import validator from "validator";

function RegisterStaff({staffData , fetchData , handleClose}) {
  const { authTokens } = useContext(AuthContext);

  const token = authTokens.access_token;

  const [formData, setFormData] = useState({
    staffname: staffData?.staffname || "",
    joining_date: staffData?.joining_date || null,
    address: staffData?.address || "",
    ph_number: staffData?.ph_number || "",
    is_permanent: staffData?.is_permanent || true,
    salary: staffData?.salary || "",
    email: staffData?.email || "",
    gender: staffData?.gender || "",
    id_proof: "",
    amount_paid: staffData?.amount_paid || 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDate = (newValue) => {
    const formattedDate = newValue.format("YYYY-MM-DD");
    setFormData({
      ...formData,
      joining_date: formattedDate,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      id_proof: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (
      !formData.staffname ||
      !formData.joining_date ||
      !formData.address ||
      !formData.ph_number ||
      !validator.isMobilePhone(formData.ph_number, "en-IN") ||
      !formData.salary ||
      !formData.email ||
      !validator.isEmail(formData.email) ||
      !formData.gender
    ) {
      toast.error("Please fill in all fields correctly");
      return;
    }

    const formToSubmit = new FormData();
  
    formToSubmit.append("staffname", formData.staffname);
    formToSubmit.append("joining_date", formData.joining_date);
    formToSubmit.append("address", formData.address);
    formToSubmit.append("ph_number", formData.ph_number);
    formToSubmit.append("is_permanent", formData.is_permanent);
    formToSubmit.append("salary", formData.salary);
    formToSubmit.append("email", formData.email);
    formToSubmit.append("gender", formData.gender);
    formToSubmit.append("amount_paid", formData.amount_paid);

    if (formData.id_proof) {
      formToSubmit.append("id_proof", formData.id_proof);
    }
    

  
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/staff/${staffData ? `${staffData.id}/` : ""}`,
        
        {
          method: staffData ? "PATCH" : "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formToSubmit,
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success(staffData ? "Staff updated successfully" : "Staff added successfully");
        // Reset form
        setFormData({
          staffname: "",
          joining_date: null,
          address: "",
          ph_number: "",
          is_permanent: true,
          salary: "",
          email: "",
          gender: "",
          id_proof: "",
          amount_paid: 0,
        });

        if (staffData) {
          fetchData();
          handleClose();
        }
        
      } else if (response.status === 400) {
        toast.error("Please fill in all fields correctly");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="flex justify-center">
      <div className={`registerStaffContainer ${
        staffData ? "!w-[100%]" : ""
      }`}>
        <form onSubmit={handleSubmit}>
          <div className="registerStaffNameAndJoiningDate w-full">
            <span className="w-[50%]">
              <TextField
                fullWidth
                margin="normal"
                label="Staff Name"
                name="staffname"
                value={formData.staffname}
                onChange={handleChange}
                // placeholder="eg. anniversary"
                sx={{ borderRadius: "8px", m: 0, height: "52px" }}
                className="registerStaffName"
              />
            </span>
            <span className="outsideloacalization_provider w-[50%]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} sx={{ p: 0 }}>
                  <DatePicker
                    className="registerStaffJoiningDate w-full"
                    label="Joining Date"
                    name="joining_date"
                    value={dayjs(formData.joining_date)}
                    sx={{ borderRadius: "8px", m: 0, p: 0 }}
                    onChange={handleDate}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </span>
           
          </div>

        
          <div className="registerStaffAddressAndContact">
            <TextField
              fullWidth
              margin="normal"
              label="Staff's Contact No."
              name="ph_number"
              type="number"
              value={formData.ph_number}
              onChange={(e)=>{
                if(e.target.value.length <= 10){
                  handleChange(e)
                }
              }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();

                }
              }}
              sx={{ borderRadius: "8px", m: 0 }}
            />
               <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ borderRadius: "8px", m: 0 }}
            />
          

          </div>

          <TextField
            fullWidth
            margin="normal"
            label="Address"
            name="address"
            multiline
            rows={4}
            value={formData.address}
            onChange={handleChange}
            sx={{ borderRadius: "8px", m: 0 }}
          />
          <div className="registerStaffSalaryAndEmail">
          <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                label='Status'
                name="is_permanent"
                value={formData.is_permanent}
                onChange={handleChange}
              >
                <MenuItem value={true}>Permanent</MenuItem>
                <MenuItem value={false}>Temporary</MenuItem>
              </Select>
            </FormControl>
         
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                label='Gender'
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-full flex gap-[5px]">
            <TextField
              fullWidth
              margin="normal"
              label="Salary"
              name="salary"
              type="number"
              value={formData.salary}
              onChange={handleChange}
              sx={{ borderRadius: "8px", m: 0 }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();

                }
              }}
            />
            {/* <span className="w-[50%]">
            <TextField
              fullWidth
              margin="normal"
              label="Amount Paid"
              name="amount_paid"
              type="number"
              value={formData.amount_paid}
              onChange={handleChange}
              sx={{ borderRadius: "8px", m: 0 }}
            />
          </span> */}
            {/* <span className="w-[50%]"> */}

            {/* <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ borderRadius: "8px", height: "50px" , backgroundColor: "transparent" }}
          > */}
            <div  className=" flex gap-1 items-start w-1/2 flex-col shrink-0 pl-1">
              <label for="file-upload" className="min-w-max ">
                Upload Document
              </label>
              <input
                type="file"
                id="file-upload"
                // hidden
                onChange={handleFileChange}
                className=" border-none !rounded-none"
              // sx={{ borderRadius: "8px" }}
              />
            </div>
            {/* </Button> */}

            {/* </span> */}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: "black",
              paddingBlock: "8px",
              borderRadius: "8px",
              width: "fit-content",
              paddingInline: "16px",
              minWidth: "100px",
            }}
          >
            {staffData ? "Update staff" : "Register Staff"}
          </Button>
        </form>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </div>
  );
}

export default RegisterStaff;
