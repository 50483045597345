import React, { useContext, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuthContext from "../../Context/Auth";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import MiniHeader from "./MiniHeader";

const MassagesList = () => {
  const { vendorData } = useContext(AuthContext);

  const [offers, setOffers] = useState([]);
  const [offerLoading, setOfferLoading] = useState(false);

  const fetchOffers = async () => {
    setOfferLoading(true);

    if (!vendorData?.spa) return;

    let url = `https://trakky.in:8000/spas/spa-profile-page-offer/?spa=${vendorData?.spa}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setOffers(data);
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setOfferLoading(false);
    }
  };
  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days !== "0") {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating !== "0") {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours !== "0") {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes !== "0") {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  useEffect(() => {
    fetchOffers();
  }, [vendorData]);

  return (
    <div className=" w-full h-full bg-[#EFECFF]">
      <ToastContainer />
      <MiniHeader title="Our offers" />
      {/* <div className=" w-full max-w-[100vw] md:max-w-[calc(100vw-288px)] h-[calc(100%-60px)] md:h-[calc(100%-68px)] p-3 overflow-auto mt-1 ">
        <div className=" w-full flex flex-col gap-2 items-center justify-center px-2 min-w-[1024px]">
          {offerLoading ? (
            <div className=" h-60 flex items-center justify-center">
              <CircularProgress
                sx={{
                  color: "#000",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          ) : offers?.length > 0 ? (
            offers?.map((item, index) => (
              <div className=" px-2 py-3 items-center gap-2 md:gap-3 w-full flex grow border border-t-slate-200 border-r-slate-200 last:border-b-slate-200 border-l-4 border-l-blue-200">
                <div className=" h-6 w-6 flex items-center justify-center">
                  <MenuIcon className=" !h-4 !w-4 text-gray-400" />
                </div>
                <div className=" grow">
                  <h3 className=" min-w-max">
                    {item?.offer_name ? `${item?.offer_name} ( ${item?.offer_type} )` : "No Offer Name"}
                  </h3>
                </div>
                <div className=" text-sm min-w-24 max-w-60">
             
                  {
                    item?.offer_percentage ? `${item?.offer_percentage}% Off` : "No Offer Percentage"
                  }
                </div>
                <div className=" w-96 shrink-0 grow-0">
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.term_and_condition }}
                    className="dangerous-html w-full"
                  ></div>
                </div>
                <div className=" min-w-16 pl-2">
                  <span className=" text-sm font-medium min-w-16 block">
                    &#8377;
                    {item?.discount_price ?? "-"}
                  </span>
                  <del className=" text-xs text-gray-400">
                    &#8377;
                    {item?.actual_price ?? "-"}
                  </del>
                </div>
              </div>
            ))
          ) : (
            <div className=" w-full h-52 flex items-center justify-center">
              <h1 className=" text-lg font-bold">No Offers Found</h1>
            </div>
          )}
        </div>
      </div> */}
      <div className="w-full h-[calc(100%-60px)] md:h-[calc(100%-68px)] mt-1">
        <div className="w-full h-full flex flex-col gap-2">
          <div className="w-full h-full pb-2 px-4 max-w-[100vw] md:max-w-[calc(100vw-288px)] overflow-y-auto overflow-x-auto">
            <table className="border-collapse w-full bg-white rounded-lg text-center min-w-max">
              <thead>
                <tr>
                  <th className="border border-gray-100 p-2">Sr no.</th>
                  <th className="border border-gray-100 p-2">Offer name</th>
                  <th className="border border-gray-100 p-2">Offer type</th>
                  <th className="border border-gray-100 p-2">
                    Offer percentage
                  </th>
                  <th className="border border-gray-100 p-2">Massage name</th>
                  <th className="border border-gray-100 p-2">Price</th>
                  <th className="border border-gray-100 p-2">Discounted price</th>
                  <th className="border border-gray-100 p-2">
                    Term and Condition
                  </th>
                  <th className="border border-gray-100 p-2">Created date</th>
                  <th className="border border-gray-100 p-2 min-w-32">
                    How to avail
                  </th>
                  <th className="border border-gray-100 p-2">Coupon code</th>
                </tr>
              </thead>
              <tbody>
                {offerLoading ? (
                  <tr className="h-40">
                    <td colSpan="11" className="mx-auto">
                      <CircularProgress
                        sx={{
                          color: "#000",
                          margin: "auto",
                        }}
                      />
                    </td>
                  </tr>
                ) : offers?.length > 0 ? (
                  offers?.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td className="p-2 border-gray-200 border">
                          {index + 1}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_name ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_type ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.offer_percentage ? `${item?.offer_percentage}%` : "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.massage_details?.service_names ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.massage_price ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.discount_price ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border dangerous-html">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.term_and_condition,
                            }}
                          />
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.created_at ? item?.created_at?.split("T")[0] : "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.how_to_avial ?? "-"}
                        </td>
                        <td className="p-2 border-gray-200 border">
                          {item?.coupon_code ?? "-"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="11" className="p-2">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MassagesList;
