import React, { useContext, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuthContext from "../../Context/Auth";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import MiniHeader from "./MiniHeader";

const MassagesList = () => {
  const { vendorData } = useContext(AuthContext);

  const [serviceLoading, setServiceLoading] = useState(false);

  const [tempAllServices, setTempAllServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState(1);

  const fetchMassages = async () => {
    setServiceLoading(true);

    if (!vendorData?.spa) return;

    try {
      const response = await fetch(
        `https://trakky.in:8000/spas/service/?page=${page}&spa_id=${vendorData?.spa}`
      );
      const data = await response.json();

      if (response.ok) {
        if (page === 1) {
          setTempAllServices(data?.results);
        } else {
          setTempAllServices([...tempAllServices, ...data?.results]);
        }

        if (data?.next) {
          setPage(page + 1);
        }
      } else {
        toast.error(`something went wrong : ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`something went wrong : ${error.message}`);
    } finally {
      setServiceLoading(false);
    }
  };

  
  const formateTime = (time) => {
    let str = "";

    if (time?.days && time?.days !== "0") {
      str += time.days + " Days, ";
    }
    if (time?.seating && time?.seating !== "0") {
      str += time.seating + " Seating, ";
    }
    if (time?.hours && time?.hours !== "0") {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes && time?.minutes !== "0") {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  useEffect(() => {
    if (tempAllServices?.length > 0) {
      setAllServices(tempAllServices);
    }
  }, [tempAllServices]);

  useEffect(() => {
    fetchMassages();
  }, [vendorData?.spa, page]);

  return (
    <div className=" w-full h-full">
      <ToastContainer />
      <MiniHeader title="Our massages" />
      <div className=" w-full max-w-[100vw] md:max-w-[calc(100vw-288px)] h-[calc(100%-60px)] md:h-[calc(100%-68px)] p-3 overflow-auto mt-1 ">
        <div className=" w-full flex flex-col gap-2 items-center justify-center px-2 min-w-[1024px]">
          {serviceLoading ? (
            <div className=" h-60 flex items-center justify-center">
              <CircularProgress
                sx={{
                  color: "#000",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          ) : allServices?.length > 0 ? (
            allServices?.map((item, index) => (
              <div className=" px-2 py-3 items-center gap-2 md:gap-3 w-full flex grow border border-t-slate-200 border-r-slate-200 last:border-b-slate-200 border-l-4 border-l-blue-200">
                <div className=" h-6 w-6 flex items-center justify-center">
                  {/* <MenuIcon className=" !h-4 !w-4 text-gray-400" /> */}
                  <span className=" font-semibold">{index + 1}.</span>
                </div>
                <div className=" grow">
                  <h3 className=" line-clamp-1">
                    {item?.service_names ?? "No massage Name"}
                  </h3>
                </div>
                <div className=" text-sm line-clamp-1 min-w-60 max-w-60">
                  {formateTime(item?.service_time)}
                </div>
                <div className=" w-96 shrink-0 grow-0">
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                    className="dangerous-html w-full"
                  ></div>
                </div>
                <div className=" min-w-16 pl-2">
                  <span className=" text-sm font-medium min-w-16 block">
                    &#8377;
                    {item?.discount ?? "-"}
                  </span>
                  <span className=" text-xs text-gray-400">
                  &#8377;
                  <del >
                  
                    {item?.price ?? "-"}
                  </del>
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className=" w-full h-52 flex items-center justify-center">
              <h1 className=" text-lg font-bold">No Massage Found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MassagesList;
