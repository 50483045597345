import React, { useContext, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Quill from "quill";
import "quill/dist/quill.snow.css";
import AuthContext from "../../../Context/Auth";
import { toast, ToastContainer } from "react-toastify";
import { Delete } from "@mui/icons-material";
import { set } from "date-fns";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";

const CreateCustomerMembership = ({ editData, onClose }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300, // Adjust width for a cleaner look
      },
    },
  };
  const editorRef = useRef(null);
  const { vendorData, authTokens } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    customer_name: "",
    customer_phone: "",
    membership_type: "",
    membership_code: "",
    terms_and_conditions: "",
    branch_name: "",
    manager: "",
    amount_paid: "",
  });

  const [managerData, setManagerData] = useState([]);
  const [membershipTypeData, setMembershipTypeData] = useState([]);

  const navigate = useNavigate();

  const [mTLoading, setMTLoading] = useState(true);
  const [selectedMembershipType, setSelectedMembershipType] = useState(
    editData?.membership_data || ""
  );

  const fetchMembershipTypes = async () => {
    setMTLoading(true);
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/membership-type-new/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMembershipTypeData(data);
      } else {
        toast.error("An error occured :" + response.statusText);
      }
    } catch (error) {
      toast.error("An error occured");
    } finally {
      setMTLoading(false);
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/manager/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setManagerData(data);
      } else {
        toast.error("An error occured :" + response.statusText);
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  useEffect(() => {
    fetchMembershipTypes();
    fetchManagers();
  }, []);


  useEffect(() => {
    editorRef.current = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "bullet" }, { list: "ordered" }],
          [{ color: [] }],
        ],
      },
    });

    // if () {
    //   editorRef.current.root.innerHTML = "";
    // }
  }, []);

  const getCustomerNameByPhone = async () => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-table/?customer_phone=${formData.customer_phone}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.length > 0) {
          setFormData({
            ...formData,
            customer_name: data[0].customer_name,
          });
        }
      } else {
        toast.error("An error occured :" + response.statusText);
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  useEffect(()=>{

    if (!editData && formData.customer_phone.length === 10) {
      getCustomerNameByPhone();
    }
  }, [formData?.customer_phone]
  );

  const getMembershipCode = async () => {
    const response = await fetch(
      "https://trakky.in:8000/spavendor/membershipcodegenerator/"
    );
    if (response.status === 200) {
      const data = await response.json();
      setFormData({ ...formData, membership_code: data.membership_code });
    } else {
      console.log("Error");
    }
  };

  const handleSubmit = async () => {
    let method = editData ? "PATCH" : "POST";
    let url = `https://trakky.in:8000/spavendor/customer-membership-new/`;

    if (editData) {
      url += `${editData.id}/`;
    }

    let payload = {
      customer_name: formData.customer_name,
      customer_phone: formData.customer_phone,
      membership_type: formData.membership_type,
      // membership_code: formData.membership_code,
      terms_and_conditions: editorRef.current.root.innerHTML,
      branch_name: formData.branch_name,
      manager: formData.manager,
      amount_paid: formData.amount_paid,
    };

    if (!editData) {
      payload = {
        ...payload,
        membership_code: formData.membership_code,
      };
    }

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        if (editData) {
          toast.success("Customer Membership updated successfully.");
        } else {
          toast.success("Customer Membership created successfully.");
        }

        editorRef.current.root.innerHTML = "";
        setFormData({
          customer_name: "",
          customer_phone: "",
          membership_type: "",
          membership_code: "",
          terms_and_conditions: "",
          branch_name: "",
          manager: "",
          amount_paid: "",
        });
        
        getMembershipCode();
        setTimeout(() => {
          if (onClose) {
            onClose();
          }
        }, 500);

        if (!editData) {
          navigate("/catalogue/membership-customer");
        }

        
      } else {
        toast.error(
          "Something went wrong while selling membership" + response.statusText
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while selling membership" + error.message
      );
    }
  };

  useEffect(() => {
    if (!editData) {
      getMembershipCode();
    }
  }, []);

  useEffect(() => {
    if (editData) {
      setFormData({
        customer_name: editData?.customer?.customer_name || "",
        customer_phone: editData?.customer?.customer_phone || "",
        membership_type: editData?.membership_type_detail?.id || "",
        membership_code: editData?.membership_code || "",
        branch_name: editData?.branch_name || "",
        manager: editData?.manager_detail?.id || "",
        amount_paid: editData?.amount_paid || "",
        membership_price:
          editData?.membership_type_detail?.membership_price || "",
      });

      console.log("editData : ", editData);

      // Ensure Quill editor is initialized before setting content
      if (editorRef.current) {
        editorRef.current.root.innerHTML = editData?.terms_and_conditions || "";
      }
    }
  }, [editData]);

  useState(() => {
    console.log("formData : ", formData);
  }, [formData]);

  return (
    <>
      <ToastContainer />
      <div className=" w-full h-[calc(100%-64px)] overflow-auto ">
        <div className=" mb-6 max-w-[724px] rounded-lg p-[25px] bg-white grid gap-x-6 gap-y-5 grid-cols-2 mx-auto py-5 md:py-8">
          <div className="w-full max-w-[350px]">
            <TextField
              id="number"
              label="Customer number"
              type="number"
              variant="outlined"
              fullWidth
              value={formData.customer_phone}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10) {
                  setFormData({ ...formData, customer_phone: value });
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              fullWidth
              id="customer-name"
              label="Customer name"
              variant="outlined"
              value={formData.customer_name}
              onChange={(e) => {
                setFormData({ ...formData, customer_name: e.target.value });
              }}
            />
          </div>

          <div className=" w-full max-w-[350px] ">
            <Autocomplete
              disablePortal
              id="service-options"
              options={membershipTypeData}
              disabled={mTLoading}
              getOptionLabel={(option) =>
                `${option?.membership_name} (${option?.membership_price})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Membership type" />
              )}
              value={
                membershipTypeData.find(
                  (item) => item.id === formData.membership_type
                ) || null
              }
              onChange={(e, value) => {
                setFormData({
                  ...formData,
                  membership_type: value?.id || "",
                  membership_price: value?.membership_price || "",
                  terms_and_conditions: value?.terms_and_conditions || "",
                });
              }}
            />
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              id="code"
              label="Membership code"
              variant="outlined"
              fullWidth
              value={formData.membership_code}
              onChange={(e) => {
                setFormData({ ...formData, membership_code: e.target.value });
              }}
              aria-readonly
              disabled
            />
          </div>

          <div className=" col-span-2 w-full max-w-[724px]">
            <label htmlFor="editor" className=" font-normal pb-2  block">
              Term and conditions
            </label>
            <div id="editor" style={{ width: "100%", height: "100px" }}></div>
          </div>

          <div className=" w-full max-w-[350px] ">
            <TextField
              id="branch"
              label="Branch name"
              variant="outlined"
              fullWidth
              value={formData.branch_name}
              onChange={(e) => {
                setFormData({ ...formData, branch_name: e.target.value });
              }}
            />
          </div>

          <div className=" w-full max-w-[350px] ">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Manager name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.manager}
                label="Manager Name"
                MenuProps={MenuProps}
                onChange={(e) => {
                  setFormData({ ...formData, manager: e.target.value });
                }}
              >
                {managerData?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.managername}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className=" w-full max-w-[350px] ">
            <TextField
              id="price"
              label="Membership price"
              type="number"
              variant="outlined"
              fullWidth
              value={formData.membership_price}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
              disabled
              InputLabelProps={{
                shrink: true, // Ensures the label remains shrunk
              }}
            />
          </div>
          <div className=" w-full max-w-[350px] ">
            <TextField
              id="price"
              label="Amount paid"
              type="number"
              variant="outlined"
              fullWidth
              value={formData.amount_paid}
              onChange={(e) => {
                setFormData({ ...formData, amount_paid: e.target.value });
              }}
              onWheel={() => document.activeElement.blur()}
              onKeyDownCapture={(event) => {
                if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className=" col-span-2">
            <button
              className=" mx-auto block bg-black text-white py-2 rounded-md px-4 w-fit"
              onClick={handleSubmit}
            >
              {editData ? "Update" : "Sell Membership"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCustomerMembership;
